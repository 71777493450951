import {User} from "../../types/user";
import api from "../api";
import {AxiosResponse} from "axios";

type GetUsersRequest = {
    query?: string;
    page?: number;
    rowsPerPage?: number;
    sortBy?: string;
    sortDir?: 'ASC' | 'DESC';
};

type GetUsersResponse = Promise<{
    data: User[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}>;

type GetUserRequest = {
    userId: number;
};

type FindUserRequest = {
    username: string;
};

type GetUserResponse = Promise<User>;

type PostBlockUser = {
    userId: number;
}

type PutUserRequest = {
    userId: number;
    avatar?: string;
    firstName: string;
    lastName: string;
    surname?: string;
    phone: string;
    telegram?: boolean;
    viber?: boolean;
    disabled: boolean;
}

class UsersApi {
    getUsers(request: GetUsersRequest = {}): GetUsersResponse {
        const {query, page, rowsPerPage, sortBy, sortDir} = request;

        const queryBuilder = new URLSearchParams();
        if (query != null && query !== '') {
            queryBuilder.append("query", query);
        }
        if (page != null) {
            queryBuilder.append("page", page.toString());
        }
        if (rowsPerPage != null) {
            queryBuilder.append("rowsPerPage", rowsPerPage.toString());
        }
        if (sortBy != null) {
            queryBuilder.append("sortBy", sortBy);
        }
        if (sortDir != null) {
            queryBuilder.append("sortDir", sortDir);
        }
        return new Promise((resolve, reject) => {
            try {
                api.get<GetUsersResponse>("/users/page?" + queryBuilder.toString())
                    .then((response: AxiosResponse<GetUsersResponse>) => resolve(response.data));
            } catch (err) {
                console.error('[Get users api]: ', err);
                reject(new Error('Internal server '));
            }
        });
    }

    getUser(request: GetUserRequest): GetUserResponse {
        // return new Promise((resolve, reject) => {
        //     try {
        //         api.get<GetUserResponse>("/users/" + request.userId)
        //             .then((response: AxiosResponse<GetUserResponse>) => resolve(response.data));
        //     } catch (err) {
        //         console.error(`[Get user=${request.userId} api ]: `, err);
        //         reject(new Error('Internal server '));
        //     }
        // });

        return api.get<GetUserResponse>("/users/" + request.userId)
            .then((response: AxiosResponse<GetUserResponse>) => response.data)
            .catch(() => {
                throw new Error("");
            });
    }

    findUser(request: FindUserRequest): GetUserResponse {
        return new Promise((resolve, reject) => {
            try {
                api.get<GetUserResponse>("/users?username=" + request.username)
                    .then((response: AxiosResponse<GetUserResponse>) => resolve(response.data));
            } catch (err) {
                console.error(`[Find user by username=${request.username} api ]: `, err);
                reject(new Error('Internal server '));
            }
        });
    }

    blockUser(request: PostBlockUser) {
        return new Promise((resolve, reject) => {
            try {
                api.post<number>("/users/" + request.userId + '/block')
                    .then((response: AxiosResponse) => resolve(response.status));
            } catch (err) {
                console.error(`[Post block user=${request.userId} api ]: `, err);
                reject(new Error('Internal server '));
            }
        });
    }

    unlockUser(request: PostBlockUser) {
        return new Promise((resolve, reject) => {
            try {
                api.post("/users/" + request.userId + '/unlock')
                    .then((response: AxiosResponse) => resolve(response.status));
            } catch (err) {
                console.error(`[Post unlock user=${request.userId} api ]: `, err);
                reject(new Error('Internal server '));
            }
        });
    }

    updateUser(request: PutUserRequest): GetUserResponse {
        return api.put<GetUserResponse>("/users/" + request.userId, {
            avatar: request.avatar,
            firstName: request.firstName,
            lastName: request.lastName,
            surname: request.surname,
            phone: request.phone,
            telegram: request.telegram,
            viber: request.viber,
            disabled: request.disabled
        })
            .then((response: AxiosResponse<GetUserResponse>) => response.data);
    }
}

export const usersApi = new UsersApi();
