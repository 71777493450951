import {combineReducers} from '@reduxjs/toolkit';
import {reducer as usersListReducer} from 'src/slices/admin/users/users-list-slices';
import {reducer as userItemReducer} from 'src/slices/admin/users/users-item-slices';
import {reducer as analyticsListReducer} from 'src/slices/admin/analytics/analytics-list-slices';
import {reducer as companiesListReducer} from 'src/slices/admin/companies/companies-list-slices';
import {reducer as companyItemReducer} from 'src/slices/admin/companies/company-item-slices';
import {reducer as employeeListReducer} from 'src/slices/admin/employees/employees-list-slices';
import {reducer as loginpiListReducer} from 'src/slices/admin/loginspi/loginspi-list-slices';
import {reducer as loginpiItemReducer} from 'src/slices/admin/loginspi/loginspi-item-slices';
import {reducer as typesListReducer} from 'src/slices/admin/settings/other/types/types-list-slices';
import {reducer as documentsListReducer} from 'src/slices/admin/documents/dompanies-list-slices';
import {reducer as templatesListReducer} from 'src/slices/admin/templates/templates-list-slices';
import {
    reducer as vchasnoListReducer
} from 'src/slices/admin/settings/integration/vchasno/vchasno-connection-list-slices';
import {
    reducer as novaPoshtaCollectionListReducer
} from 'src/slices/admin/settings/integration/novaposhta/novaposhta-connection-list-slices';
import {
    reducer as vchasnoIntegrationListReducer
} from 'src/slices/admin/integrations/vchasno/vchasno-documents-list-slices';
import {
    reducer as novaPoshtaIntegrationListReducer
} from 'src/slices/admin/integrations/novaposhta/novaposhta-documents-list-slices';
import {reducer as paymentsListReducer} from 'src/slices/admin/payments/payments-list-slices';
import {reducer as paymentsItemReducer} from 'src/slices/admin/payments/payments-item-slices';
import {reducer as paymentMethodsReducer} from 'src/slices/admin/payments/method/payment-method-list-slices';
import {
    reducer as autoEnrollPaymentReducer
} from 'src/slices/admin/settings/payment/auto/auto-enroll-payment-list-slices';

export const rootReducer = combineReducers({
    usersList: usersListReducer,
    userItem: userItemReducer,
    analyticsList: analyticsListReducer,
    companiesList: companiesListReducer,
    companyItem: companyItemReducer,
    employeesList: employeeListReducer,
    loginpiList: loginpiListReducer,
    loginpiItem: loginpiItemReducer,
    typesList: typesListReducer,
    documentsList: documentsListReducer,
    templatesList: templatesListReducer,
    vchasnoListReducer: vchasnoListReducer,
    novaPoshtaCollectionListReducer: novaPoshtaCollectionListReducer,
    vchasnoIntegrationListReducer: vchasnoIntegrationListReducer,
    novaPoshtaIntegrationListReducer: novaPoshtaIntegrationListReducer,
    paymentsListReducer: paymentsListReducer,
    paymentsItemReducer: paymentsItemReducer,
    paymentMethodsReducer: paymentMethodsReducer,
    autoEnrollPaymentReducer: autoEnrollPaymentReducer,
});
