import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PaymentMethod} from "../../../../types/payment";

interface PaymentMethodsState {
    isPaymentMethodsLoading: boolean;
    paymentMethods: PaymentMethod[];
}

const initialState: PaymentMethodsState = {
    isPaymentMethodsLoading: false,
    paymentMethods: [],
};


type PaymentMethodsLoadingAction = PayloadAction<boolean>;
type GetPaymentMethodsAction = PayloadAction<PaymentMethod[]>;
type VisiblePaymentMethodAction = PayloadAction<number>;

const reducers = {
    onPaymentMethodLoadingChange(state: PaymentMethodsState, action: PaymentMethodsLoadingAction): void {
        state.isPaymentMethodsLoading = action.payload;
    },

    onPaymentMethodsChange(state: PaymentMethodsState, action: GetPaymentMethodsAction): void {
        state.paymentMethods = action.payload;
    },

    onVisiblePaymentMethodChange(state: PaymentMethodsState, action: VisiblePaymentMethodAction): void {
        let paymentMethods = [...state.paymentMethods];
        paymentMethods = paymentMethods.map((paymentMethod) => {
            if (paymentMethod.id === action.payload) {
                paymentMethod.enabled = !paymentMethod.enabled;
            }
            return paymentMethod;
        })
        state.paymentMethods = paymentMethods;
    },
}


export const slice = createSlice({
    name: 'paymentMethodsList',
    initialState,
    reducers
});

export const {reducer} = slice;