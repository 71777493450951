import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AutoEnrollPaymentFilter, AutoEnrollPaymentPage} from "../../../../../types/auto-payment";

interface AutoEnrollPaymentState {
    isAutoEnrollPaymentsLoading: boolean;
    autoEnrollPaymentPage: AutoEnrollPaymentPage;
    autoEnrollPaymentFilter: AutoEnrollPaymentFilter;
}

const initialState: AutoEnrollPaymentState = {
    isAutoEnrollPaymentsLoading: false,
    autoEnrollPaymentPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    autoEnrollPaymentFilter: {
        page: 0,
        rowsPerPage: 20,
    }
};

type AutoEnrollPaymentLoadingAction = PayloadAction<boolean>;
type GetAutoEnrollPaymentPageAction = PayloadAction<AutoEnrollPaymentPage>;
type DeleteAutoEnrollPaymentAction = PayloadAction<number>;
type ChangeAutoEnrollPaymentFilterAction = PayloadAction<AutoEnrollPaymentFilter>;

const reducers = {
    onAutoEnrollPaymentsLoadingChange(state: AutoEnrollPaymentState, action: AutoEnrollPaymentLoadingAction): void {
        state.isAutoEnrollPaymentsLoading = action.payload;
    },
    onPaymentsFilterChange(state: AutoEnrollPaymentState, action: ChangeAutoEnrollPaymentFilterAction): void {
        state.autoEnrollPaymentFilter = action.payload;
    },
    onAutoEnrollPaymentPageChange(state: AutoEnrollPaymentState, action: GetAutoEnrollPaymentPageAction): void {
        state.autoEnrollPaymentPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onAutoEnrollPaymentDelete(state: AutoEnrollPaymentState, action: DeleteAutoEnrollPaymentAction): void {
        const autoEnrollPaymentPage = [...state.autoEnrollPaymentPage.data];
        const index =
            autoEnrollPaymentPage.findIndex((autoEnrollPayment) => autoEnrollPayment.id === action.payload)
        if (index > -1) {
            autoEnrollPaymentPage.splice(index, 1);
        }
        state.autoEnrollPaymentPage.data = autoEnrollPaymentPage;
    },
}


export const slice = createSlice({
    name: 'autoEnrollPaymentList',
    initialState,
    reducers
});

export const {reducer} = slice;