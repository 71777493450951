import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NovaPoshtaConnection} from "../../../../../types/novaposhta";

interface NovaPoshtaConnectionsState {
    isNovaPoshtaConnectionsLoading: boolean,
    novaPoshtaConnections: NovaPoshtaConnection[];
}

const initialState: NovaPoshtaConnectionsState = {
    isNovaPoshtaConnectionsLoading: false,
    novaPoshtaConnections: []
};

type LoadingNovaPoshtaConnections = PayloadAction<boolean>;
type GetNovaPoshtaConnectionsAction = PayloadAction<NovaPoshtaConnection[]>;
type DeleteNovaPoshtaConnectionAction = PayloadAction<number>;
type CreateNovaPoshtaConnectionAction = PayloadAction<NovaPoshtaConnection>;
type UpdateNovaPoshtaConnectionAction = PayloadAction<NovaPoshtaConnection>;

const reducers = {
    onLoadingNovaPoshtaConnections(state: NovaPoshtaConnectionsState, action: LoadingNovaPoshtaConnections): void {
        state.isNovaPoshtaConnectionsLoading = action.payload;
    },
    onGetNovaPoshtaConnections(state: NovaPoshtaConnectionsState, action: GetNovaPoshtaConnectionsAction): void {
        state.novaPoshtaConnections = action.payload;
    },
    onCreateVchasnoConnection(state: NovaPoshtaConnectionsState, action: CreateNovaPoshtaConnectionAction): void {
        const novaPoshtaConnections = [...state.novaPoshtaConnections];
        novaPoshtaConnections.push(action.payload);
        state.novaPoshtaConnections = novaPoshtaConnections;
    },
    onDeleteNovaPoshtaConnection(state: NovaPoshtaConnectionsState, action: DeleteNovaPoshtaConnectionAction): void {
        const novaPoshtaConnections = [...state.novaPoshtaConnections];
        const index = novaPoshtaConnections.findIndex((novaPoshtaConnection) => novaPoshtaConnection.id === action.payload)
        if (index > -1) {
            novaPoshtaConnections.splice(index, 1);
        }
        state.novaPoshtaConnections = novaPoshtaConnections;
    },
    onUpdateNovaPoshtaConnection(state: NovaPoshtaConnectionsState, action: UpdateNovaPoshtaConnectionAction): void {
        let novaPoshtaConnections = [...state.novaPoshtaConnections];
        novaPoshtaConnections = novaPoshtaConnections.map((novaPoshtaConnection) => {
            if (novaPoshtaConnection.id === action.payload.id) {
                novaPoshtaConnection.name = action.payload.name;
                novaPoshtaConnection.apiToken = action.payload.apiToken;
            }
            return novaPoshtaConnection;
        })
        state.novaPoshtaConnections = novaPoshtaConnections;
    },
}

export const slice = createSlice({
    name: 'novaposhtaConnections',
    initialState,
    reducers
});

export const {reducer} = slice;