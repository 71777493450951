import {lazy} from 'react';
import type {RouteObject} from 'react-router';
import {Outlet} from 'react-router-dom';
import {IssuerGuard} from 'src/guards/issuer-guard';
import {GuestGuard} from 'src/guards/guest-guard';
import {Layout as AuthLayout} from 'src/layouts/auth/classic-layout';
import {Issuer} from 'src/utils/auth';

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

export const authRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
        <IssuerGuard issuer={Issuer.JWT}>
          <GuestGuard>
              <AuthLayout>
                <Outlet/>
              </AuthLayout>
          </GuestGuard>
        </IssuerGuard>
    ),
    children: [
      {
        index: true,
        element: <JwtLoginPage />
      },
      {
        path: 'login',
        element: <JwtLoginPage />
      },
      {
        path: 'register',
        element: <JwtRegisterPage />
      }
    ]
  }
];
