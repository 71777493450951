import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Loginpi, LoginPIFees, LoginPIPayment} from "../../../types/loginpi";

interface LoginsPIPaymentPage {
    data: LoginPIPayment[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface LoginsPIFeesPage {
    data: LoginPIFees[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface LoginPIPaymentSearch {
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface LoginPIFeesSearch {
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface LoginPIState {
    isLoadingLoginPI: boolean;
    isLoadingLoginPIPayment: boolean;
    isLoadingLoginPIFees: boolean;
    loginPIItem: Loginpi;
    loginPIPaymentPage: LoginsPIPaymentPage;
    loginPIFeesPage: LoginsPIFeesPage;
    loginPIPaymentSearch: LoginPIPaymentSearch;
    loginPIFeesSearch: LoginPIFeesSearch;
}

interface LoginsPiPaymentAction {
    data: LoginPIPayment[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type LoadingLoginPIAction = PayloadAction<boolean>;
type LoadingLoginPIPaymentAction = PayloadAction<boolean>;
type LoadingLoginPIFeesAction = PayloadAction<boolean>;
type GetLoginPIAction = PayloadAction<Loginpi>;
type GetLoginPIPaymentAction = PayloadAction<LoginsPiPaymentAction>;
type GetLoginPIFeesAction = PayloadAction<LoginsPIFeesPage>;
type FilterAction = PayloadAction<LoginPIPaymentSearch>;
type FilterFeesAction = PayloadAction<LoginPIFeesSearch>;

const initialState: LoginPIState = {
    isLoadingLoginPI: false,
    isLoadingLoginPIPayment: false,
    isLoadingLoginPIFees: false,
    loginPIItem: {
        uid: 0,
        gid: 0,
        billId: 0,
        login: "",
        fio: "",
        comments: "",
        companyEdrpou: "",
        companyName: "",
        providerEdrpou: "",
        deposit: 0,
        tariffFee: 0,
        contractId: "",
        contractDate: "",
        disable: false,
        deleted: false,
        created: ""
    },
    loginPIPaymentPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    loginPIPaymentSearch: {
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id',
    },
    loginPIFeesPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    loginPIFeesSearch: {
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id',
    }
};


const reducers = {
    onLoadingLoginPIChange(state: LoginPIState, action: LoadingLoginPIAction): void {
        state.isLoadingLoginPI = action.payload;
    },
    onLoadingLoginPIPaymentChange(state: LoginPIState, action: LoadingLoginPIPaymentAction): void {
        state.isLoadingLoginPIPayment = action.payload;
    },
    onLoadingLoginPIFeesChange(state: LoginPIState, action: LoadingLoginPIFeesAction): void {
        state.isLoadingLoginPIFees = action.payload;
    },
    onGetLoginPI(state: LoginPIState, action: GetLoginPIAction): void {
        state.loginPIItem = action.payload
    },
    onFilterPaymentChange(state: LoginPIState, action: FilterAction): void {
        state.loginPIPaymentSearch = action.payload;
    },
    onGetPageOfLoginPiPayment(state: LoginPIState, action: GetLoginPIPaymentAction): void {
        state.loginPIPaymentPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onFilterFeesChange(state: LoginPIState, action: FilterFeesAction): void {
        state.loginPIFeesSearch = action.payload;
    },
    onGetPageOfLoginPiFees(state: LoginPIState, action: GetLoginPIFeesAction): void {
        state.loginPIFeesPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    }
}

export const slice = createSlice({
    name: 'loginPIItem',
    initialState,
    reducers
});

export const {reducer} = slice;