import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {NovaPoshtaDocument, NovaPoshtaDocumentFilter, NovaPoshtaDocumentType} from "../../../../types/novaposhta";

interface NovaPoshtaDocumentsState {
    isNovaPoshtaDocumentsLoading: boolean;
    novaPoshtaDocumentsPage: NovaPoshtaDocumentPage;
    novaPoshtaDocumentsFilter: NovaPoshtaDocumentFilter;
}

const initialState: NovaPoshtaDocumentsState = {
    isNovaPoshtaDocumentsLoading: false,
    novaPoshtaDocumentsPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    novaPoshtaDocumentsFilter: {
        query: '',
        type: NovaPoshtaDocumentType.INCOMING_DOCUMENT,
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id'
    }
};

interface NovaPoshtaDocumentPage {
    data: NovaPoshtaDocument[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type NovaPoshtaDocumentsLoadingAction = PayloadAction<boolean>;
type GetPageOfNovaPoshtaDocumentsAction = PayloadAction<NovaPoshtaDocumentPage>;
type NovaPoshtaDocumentsFilterAction = PayloadAction<NovaPoshtaDocumentFilter>;

const reducers = {
    onNovaPoshtaDocumentsLoadingChange(state: NovaPoshtaDocumentsState, action: NovaPoshtaDocumentsLoadingAction): void {
        state.isNovaPoshtaDocumentsLoading = action.payload;
    },

    onNovaPoshtaDocumentsFilterChange(state: NovaPoshtaDocumentsState, action: NovaPoshtaDocumentsFilterAction): void {
        state.novaPoshtaDocumentsFilter = action.payload;
    },

    onPageOfNovaPoshtaDocuments(state: NovaPoshtaDocumentsState, action: GetPageOfNovaPoshtaDocumentsAction): void {
        state.novaPoshtaDocumentsPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
}


export const slice = createSlice({
    name: 'novaPoshtaDocumentsList',
    initialState,
    reducers
});

export const {reducer} = slice;