import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Company} from "../../../types/company";

interface CompaniesState {
    isLoadingCompanies: boolean;
    companiesPage: CompaniesPage;
    companiesSearch: CompaniesSearch;
}

const initialState: CompaniesState = {
    isLoadingCompanies: false,
    companiesPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    companiesSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id',
    }
};

interface CompaniesPage {
    data: Company[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface CompaniesSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface CompaniesAction {
    data: Company[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetCompaniesAction = PayloadAction<CompaniesAction>;

type DeleteCompanyAction = PayloadAction<number>;

type LoadingCompaniesAction = PayloadAction<boolean>;

type FilterAction = PayloadAction<CompaniesSearch>;

const reducers = {
    onLoadingCompaniesChange(state: CompaniesState, action: LoadingCompaniesAction): void {
        state.isLoadingCompanies = action.payload;
    },

    onFilterChange(state: CompaniesState, action: FilterAction): void {
        state.companiesSearch = action.payload;
    },

    onCompanies(state: CompaniesState, action: GetCompaniesAction): void {
        state.companiesPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },

    onDeleteCompany(state: CompaniesState, action: DeleteCompanyAction): void {
        const companies = [...state.companiesPage.data];
        const index = companies.findIndex((company) => company.id === action.payload)
        if (index > -1) {
            companies.splice(index, 1);
        }
        state.companiesPage.data = companies;
    }
}


export const slice = createSlice({
    name: 'companiesList',
    initialState,
    reducers
});

export const {reducer} = slice;