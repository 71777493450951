import {User} from "../../../types/user";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface UsersPage {
    data: User[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface UsersSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
    sortDir: 'ASC' | 'DESC';
}

interface UsersState {
    isLoadingUsers: boolean;
    usersPage: UsersPage;
    usersSearch: UsersSearch;
}

interface UsersAction {
    data: User[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetUsersAction = PayloadAction<UsersAction>;

type DeleteUserAction = PayloadAction<number>;

type LoadingUsersAction = PayloadAction<boolean>;

type FilterAction = PayloadAction<UsersSearch>;

const initialState: UsersState = {
    isLoadingUsers: false,
    usersPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    usersSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id',
        sortDir: 'ASC',
    }
};


const reducers = {
    onLoadingUsersChange(state: UsersState, action: LoadingUsersAction): void {
        state.isLoadingUsers = action.payload;
    },

    onFilterChange(state: UsersState, action: FilterAction): void {
        state.usersSearch = action.payload;
    },

    onGetUsers(state: UsersState, action: GetUsersAction): void {
        state.usersPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },

    onDeleteUser(state: UsersState, action: DeleteUserAction): void {
        const users = [...state.usersPage.data];
        const index = users.findIndex((user) => user.userId === action.payload)
        if (index > -1) {
            users.splice(index, 1);
        }
        state.usersPage.data = users;
    }
}

export const slice = createSlice({
    name: 'users',
    initialState,
    reducers
});

export const {reducer} = slice;