// @ts-ignore
import {React, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {SvgIcon} from '@mui/material';
import BarChartSquare02Icon from 'src/icons/untitled-ui/duocolor/bar-chart-square-02';
import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import {tokens} from 'src/locales/tokens';
import {paths} from 'src/paths';
import LineChartUp04 from "../../icons/untitled-ui/duocolor/line-chart-up-04";
import User03Icon from "@untitled-ui/icons-react/build/esm/User03";
import File04 from "../../icons/untitled-ui/duocolor/file-04";
import Share07 from "../../icons/untitled-ui/duocolor/share-07";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WebhookIcon from '@mui/icons-material/Webhook';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';

export const useAdminSections = () => {
    const {t} = useTranslation();

    return useMemo(
        () => {
            return [
                {
                    items: [
                        {
                            title: t(tokens.nav.overview),
                            path: paths.admin.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <HomeSmileIcon/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.analytics),
                            path: paths.admin.analytics,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <BarChartSquare02Icon/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.account),
                            path: paths.admin.account,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <HomeSmileIcon/>
                                </SvgIcon>
                            )
                        },
                    ]
                },
                {
                    subheader: t(tokens.nav.main),
                    items: [
                        {
                            title: t(tokens.nav.users),
                            path: paths.admin.users.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <User03Icon/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.companies),
                            path: paths.admin.companies.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <LineChartUp04/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.connectionPoints),
                            path: paths.admin.logins.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <Share07/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.payments),
                            path: paths.admin.payments.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <PointOfSaleIcon/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.documents),
                            path: paths.admin.documents.index,
                            icon: (
                                <SvgIcon fontSize="small">
                                    <File04/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.employees),
                            path: paths.admin.employees.index,
                            icon: (
                                <SvgIcon fontSize="medium">
                                    <PeopleOutlineIcon/>
                                </SvgIcon>
                            )
                        },
                        {
                            title: t(tokens.nav.integration),
                            icon: (
                                <SvgIcon fontSize="medium">
                                    <WebhookIcon/>
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.novaposhta),
                                    items: [
                                        {
                                            title: t(tokens.nav.novaPoshtaOuthoingDocument),
                                            path: paths.admin.integration.novaPoshtaOutgoingDocuments
                                        },
                                        {
                                            title: t(tokens.nav.novaPoshtaIncomingDocument),
                                            path: paths.admin.integration.novaPoshtaIncomingDocuments
                                        },
                                        {
                                            title: t(tokens.nav.novaPoshtaArchiveDocument),
                                            path: paths.admin.integration.novaPoshtaArchiveDocuments
                                        }
                                    ]
                                },
                                {
                                    title: t(tokens.nav.vchasno),
                                    items: [
                                        {
                                            title: t(tokens.nav.vchasnoDocument),
                                            path: paths.admin.integration.vchasnoDocuments
                                        },
                                        {
                                            title: t(tokens.nav.vchasnoIncomingDocument),
                                            path: paths.admin.integration.vchasnoIncomingDocument
                                        }
                                    ]
                                },
                            ]
                        },
                        {
                            title: t(tokens.nav.settings),
                            icon: (
                                <SvgIcon fontSize="small">
                                    <SettingsOutlinedIcon/>
                                </SvgIcon>
                            ),
                            items: [
                                {
                                    title: t(tokens.nav.templates),
                                    path: paths.admin.settings.templates
                                },
                                {
                                    title: t(tokens.nav.integration),
                                    items: [
                                        {
                                            title: t(tokens.nav.vchasno),
                                            path: paths.admin.settings.integration.vchasno
                                        },
                                        {
                                            title: t(tokens.nav.novaposhta),
                                            path: paths.admin.settings.integration.novaposhta
                                        },
                                        {
                                            title: t(tokens.nav.others),
                                            path: paths.admin.settings.integration.others
                                        }
                                    ]
                                },
                                {
                                    title: t(tokens.nav.directory),
                                    path: paths.admin.settings.directory
                                },
                                {
                                    title: t(tokens.nav.payment),
                                    items: [
                                        {
                                            title: t(tokens.nav.paymentAutomatic),
                                            path: paths.admin.settings.payment.automatic
                                        },
                                        {
                                            title: t(tokens.nav.paymentMethod),
                                            path: paths.admin.settings.payment.method
                                        },
                                    ]
                                },
                            ]
                        },
                    ]
                }

            ];
        },
        [t]
    );
};
