import type {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';
import type {Theme} from '@mui/material';
import {useMediaQuery} from '@mui/material';
import {styled} from '@mui/material/styles';
import type {NavColor} from 'src/types/settings';
import {MobileNav} from '../mobile-nav';
import {SideNav} from './side-nav';
import {TopNav} from './top-nav';
import {useMobileNav} from './use-mobile-nav';
import {Section} from "../../../types/section";
import {useDialog} from "../../../hooks/use-dialog";
import {HelpButtonDialog} from "../help-button";

const SIDE_NAV_WIDTH = 280;

const VerticalLayoutRoot = styled('div')(
  ({ theme }) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: SIDE_NAV_WIDTH
    }
  })
);

const VerticalLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

interface VerticalLayoutProps {
  children?: ReactNode;
  navColor?: NavColor;
  sections?: Section[];
}

export const VerticalLayout: FC<VerticalLayoutProps> = (props) => {
  const { children, sections, navColor } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const mobileNav = useMobileNav();
  const helpDialog = useDialog();

  return (
      <>
          <TopNav onMobileNavOpen={mobileNav.handleOpen}/>
          {lgUp && (
              <SideNav
                  color={navColor}
                  sections={sections}
                  enabledHelpDialog={helpDialog.handleOpen}
              />
          )}
          {!lgUp && (
              <MobileNav
                  color={navColor}
                  onClose={mobileNav.handleClose}
                  open={mobileNav.open}
                  sections={sections}
                  enabledHelpDialog={helpDialog.handleOpen}
              />
          )}
          <VerticalLayoutRoot>
              <VerticalLayoutContainer>
                  {children}
                  <HelpButtonDialog
                      open={helpDialog.open}
                      onClose={helpDialog.handleClose}
                  />
              </VerticalLayoutContainer>
          </VerticalLayoutRoot>
      </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf<NavColor>(['blend-in', 'discreet', 'evident']),
  sections: PropTypes.array
};
