import api from "../api";
import {AxiosResponse} from "axios";
import {Admin} from "../../types/admin";

type GetAdminResponse = Promise<Admin>;

class AdminApi {
    getAdminCurrent(): GetAdminResponse {
        return api.get<GetAdminResponse>("internal-rest/v1/admins/current")
            .then((response: AxiosResponse<GetAdminResponse>) => response.data);
    }
}

export const adminApi = new AdminApi();
