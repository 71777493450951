import type {SVGProps} from 'react';

const LineChartUp04 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="currentColor"
      d="M7.8 21h8.4c1.6802 0 2.5202 0 3.162-.327a2.9994 2.9994 0 0 0 1.311-1.311C21 18.7202 21 17.8802 21 16.2V9l-6.4485 4.6061c-.196.1399-.2939.2099-.3988.231a.4996.4996 0 0 1-.2765-.0229c-.1-.038-.1852-.1232-.3554-.2934l-3.0416-3.0416c-.1702-.1702-.2554-.2554-.3554-.2934a.4996.4996 0 0 0-.2765-.0229c-.1049.0211-.2028.0911-.3988.231L3 15v1.2c0 1.6802 0 2.5202.327 3.162a2.9997 2.9997 0 0 0 1.311 1.311C5.2798 21 6.1198 21 7.8 21Z"
      opacity={0.12}
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m21 9-6.4485 4.6061c-.196.1399-.2939.2099-.3988.231a.4996.4996 0 0 1-.2765-.0229c-.1-.038-.1852-.1232-.3554-.2934l-3.0416-3.0416c-.1702-.1702-.2554-.2554-.3554-.2934a.4996.4996 0 0 0-.2765-.0229c-.1049.0211-.2028.0911-.3988.231L3 15m4.8 6h8.4c1.6802 0 2.5202 0 3.162-.327a2.9994 2.9994 0 0 0 1.311-1.311C21 18.7202 21 17.8802 21 16.2V7.8c0-1.6802 0-2.5202-.327-3.162a2.9997 2.9997 0 0 0-1.311-1.311C18.7202 3 17.8802 3 16.2 3H7.8c-1.6802 0-2.5202 0-3.162.327a3 3 0 0 0-1.311 1.311C3 5.2798 3 6.1198 3 7.8v8.4c0 1.6802 0 2.5202.327 3.162a2.9997 2.9997 0 0 0 1.311 1.311C5.2798 21 6.1198 21 7.8 21Z"
    />
  </svg>
);

export default LineChartUp04;
