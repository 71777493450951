import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import {ua} from "./translations/ua";

i18n
  .use(initReactI18next)
  .init({
    resources: {
        ua: { translation: ua },
    },
    lng: 'ua',
    fallbackLng: 'ua',
    interpolation: {
      escapeValue: false
    }
  });
