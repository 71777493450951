import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Payment, PaymentFilter, PaymentStatus} from "../../../types/payment";

interface PaymentsState {
    isPaymentsLoading: boolean;
    paymentsPage: PaymentPage;
    paymentsFilter: PaymentFilter;
}

const initialState: PaymentsState = {
    isPaymentsLoading: false,
    paymentsPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    paymentsFilter: {
        query: '',
        partnerEdrpou: '',
        page: 0,
        rowsPerPage: 20,
        sortBy: 'id'
    }
};

interface PaymentPage {
    data: Payment[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}


type PaymentsLoadingAction = PayloadAction<boolean>;

type GetPaymentsPageAction = PayloadAction<PaymentPage>;
type PaymentStatusChangeAction = PayloadAction<{
    statementId: number,
    status: PaymentStatus;
}>;

type PaymentsFilterAction = PayloadAction<PaymentFilter>;

const reducers = {
    onPaymentsLoadingChange(state: PaymentsState, action: PaymentsLoadingAction): void {
        state.isPaymentsLoading = action.payload;
    },

    onPaymentsFilterChange(state: PaymentsState, action: PaymentsFilterAction): void {
        state.paymentsFilter = action.payload;
    },

    onPaymentsPageChange(state: PaymentsState, action: GetPaymentsPageAction): void {
        state.paymentsPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onPaymentChange(state: PaymentsState, action: PaymentStatusChangeAction): void {
        let payments = [...state.paymentsPage.data];
        payments = payments.map((payment) => {
            if (payment.statementId === action.payload.statementId) {
                payment.status = action.payload.status;
            }
            return payment;
        })
        state.paymentsPage.data = payments;
    },
}


export const slice = createSlice({
    name: 'paymentsList',
    initialState,
    reducers
});

export const {reducer} = slice;