export const tokens = {
  help: {
    successSendMessage: "help.successSendMessage"
  },
  common: {
    billing: {
      title: "common.billing.title",
      open: "common.billing.open",
    },
    dueDate: "common.dueDate",
    currency: "common.currency",
    download: "common.download",
    delete: "common.delete",
    warning: "common.warning",
    success: "common.success",
    languageChanged: "common.languageChanged",
    wrong: "common.wrong",
    number: "common.number",
    notifications: "common.notifications",
    numberDoc: "common.numberDoc",
    date: "common.date",
    size: "common.size",
    edrpou: "common.edrpou",
    next: "common.next",

    actions: "common.actions",
    action: "common.action",
    validUntil: "common.validUntil",
    yes: "common.yes",
    no: "common.no",

    submit: "common.submit",
    export: "common.export",
    addNewType: "common.addNewType",
    cancel: "common.cancel",
    generate: "common.generate",
    comment: "common.comment",

    sortBy: "common.sortBy",
    search: "common.search",
    actionFrom: "common.actionFrom",
    actionTo: "common.actionTo",

    phone: "common.phone",
    status: "common.status",
    fio: "common.fio",
    etc: "common.etc",
    companyIban: "common.companyIban",

    regulations: "common.regulations",
    positionWork: "common.positionWork",
    headName: "common.headName",
    stepHead: "common.companiesStepHead",
    client: "common.companiesStepClient",
    director: "common.director",
    doctor: "common.doctor",

    company: "common.companiesStepCompany",
    companyName: "common.companyName",
    docExchange: "common.docExchange",
    position: "common.position",
    order: "common.order",
    law: "common.law",
    powerOfAttorney: "common.powerOfAttorney",
    contract: "common.contract",
    document: "common.document",
    manualOptions: "common.manualOptions",

    selectUser: "common.selectUser",
    userNotFound: "common.userNotFound",
    enterValue: "common.enterValue",
    addContact: "common.addContact",
    addContactNew: "common.addContact",

    contact: "common.contact",
    typeContact: "common.typeContacts",
    contacts: "common.contacts",
    contactMissing: "common.contactMissing",

    listEmpty: "common.listEmpty",
    typeName: "common.typeName",
    showSelected: "common.showSelected",
    attachDocuments: "common.attachDocuments",
    typeContacts: "common.typeContacts",
    addTypeContact: "common.addTypeContact",
    typeDoc: "common.typeDoc",
    addTypeDoc: "common.addTypeDoc",
    typeExchangeDoc: "common.typeExchangeDoc",
    post: "common.post",
    addExchangeDoc: "common.addExchangeDoc",
    addPost: "common.addPost",
    warningTitle: "common.warningTitle",
    warningDescription: "common.warningDescription",
    typeTemplate: "common.typeTemplate",
    generated: "common.generated",
    created: "common.created",
    version: "common.version",
    listTemplatesEmpty: "common.listTemplatesEmpty",
    template: "common.template",
    templates: "common.templates",
    searchTemplate: "common.searchTemplate",
    googleDrive: "common.googleDrive",
  },

  nav: {
    help: "nav.help",
    academy: "nav.academy",
    account: "nav.account",
    analytics: "nav.analytics",
    auth: "nav.auth",
    blog: "nav.blog",
    browse: "nav.browse",
    calendar: "nav.calendar",
    chat: "nav.chat",
    checkout: "nav.checkout",
    main: "nav.main",
    contact: "nav.contact",
    course: "nav.course",
    create: "nav.create",
    crypto: "nav.crypto",
    customers: "nav.customers",

    general: "nav.general",
    logs: "nav.logs",
    ecommerce: "nav.ecommerce",
    edit: "nav.edit",
    error: "nav.error",
    feed: "nav.feed",
    fileManager: "nav.fileManager",
    files: "nav.files",
    finance: "nav.finance",
    fleet: "nav.fleet",
    forgotPassword: "nav.forgotPassword",
    invoiceList: "nav.invoices",
    jobList: "nav.jobList",
    kanban: "nav.kanban",
    list: "nav.list",
    login: "nav.login",
    logistics: "nav.logistics",
    mail: "nav.mail",
    management: "nav.management",
    orderList: "nav.orders",
    overview: "nav.overview",
    pages: "nav.pages",
    postCreate: "nav.postCreate",
    postDetails: "nav.postDetails",
    postList: "nav.postList",
    pricing: "nav.pricing",
    productList: "nav.products",
    profile: "nav.profile",
    templates: "nav.templates",
    integration: "nav.integration",
    others: "nav.others",
    vchasno: "nav.vchasno",
    novaposhta: "nav.novaposhta",
    vchasnoDocument: "nav.vchasnoDocument",
    vchasnoIncomingDocument: "nav.vchasnoIncomingDocument",
    novaPoshtaOuthoingDocument: "nav.novaPoshtaOuthoingDocument",
    novaPoshtaIncomingDocument: "nav.novaPoshtaIncomingDocument",
    novaPoshtaArchiveDocument: "nav.novaPoshtaArchiveDocument",
    types: "nav.types",
    directory: "nav.directory",
    services: "nav.services",
    register: "nav.register",
    resetPassword: "nav.resetPassword",
    socialMedia: "nav.socialMedia",
    verifyCode: "nav.verifyCode",
    logout: "nav.logout",
    setting: "nav.setting",
    phone: "nav.phone",
    phoneRequired: "nav.phoneRequired",
    status: "nav.status",
    actions: "nav.actions",
    delete: "nav.delete",
    number: "nav.number",
    email: "nav.email",
    emailValid: "nav.emailValid",
    emailRequired: "nav.emailRequired",
    sortBy: "nav.sortBy",
    labelRowsPerPage: "nav.labelRowsPerPage",
    addButton: "nav.addButton",
    approved: "nav.approved",
    notApproved: "nav.notApproved",
    all: "nav.all",
    update: "nav.update",
    updateDone: "nav.updateDone",
    further: "nav.further",
    generation: "nav.generation",
    download: "nav.download",
    filter: "nav.filter",
    close: "nav.close",
    open: "nav.open",

    username: "nav.username",
    usernameRequired: "nav.usernameRequired",
    password: "nav.password",
    passwordRequired: "nav.passwordRequired",
    doNotAccount: "nav.doNotAccount",
    alreadyHaveAccount: "nav.alreadyHaveAccount",
    authorization: "nav.authorization",

    fioRequired: "nav.fioRequired",
    provider: "nav.provider",
    financing: "nav.financing",
    details: "nav.details",
    documents: "nav.documents",
    sentDocuments: "nav.sentDocuments",
    connectionPoints: "nav.connectionPoints",
    payments: "nav.payments",
    payment: "nav.payment",
    paymentMethod: "nav.paymentMethod",
    paymentAutomatic: "nav.paymentAutomatic",
    seeMore: "nav.seeMore",
    loginTitle: "nav.loginTitle",
    loginsTitle: "nav.loginsTitle",
    balance: "nav.balance",
    numberOfDocument: "nav.numberOfDocument",
    employees: "nav.employees",
    settings: "nav.settings",
    notifications: "nav.notifications",
    notificationsMarkAsRead: "nav.notificationsMarkAsRead",
    notificationsEmpty: "nav.notificationsEmpty",
    downloadTemplate: "nav.downloadTemplate",

    //LOGIN
    loginActive: "nav.loginActive",
    loginDisable: "nav.loginDisable",
    loginAddress: "nav.loginAddress",

    //LOGINS-PAGE
    loginPIPage: "nav.loginPIPage",
    loginsSearchPlaceholder: "nav.loginsSearchPlaceholder",
    companyTableTitle: "nav.companyTableTitle",

    //COMPANIES
    searchCompanies: "nav.searchCompanies",
    companies: "nav.companies",
    companiesSearchPlaceholder: "nav.companiesSearchPlaceholder",
    companiesSortById: "nav.companiesSortById",
    companiesSortByName: "nav.companiesSortByName",
    companiesSortByProviderName: "nav.companiesSortByProviderName",
    companyConfirmed: "nav.companyConfirmed",
    companyDetails: "nav.companyDetails",
    companyNotConfirmed: "nav.companyNotConfirmed",
    counterparty: "nav.counterparty",
    companyType: "nav.companyType",
    dateOfRegistration: "nav.dateOfRegistration",
    basis: "nav.basis",
    mailAddress: "nav.mailAddress",
    connectionPointsNot: "nav.connectionPointsNot",
    addCompany: "nav.addCompany",
    companyBudgetary: "nav.companyBudgetary",
    companyPrivate: "nav.companyPrivate",
    awaitingConfirmation: "nav.awaitingConfirmation",

    //COMPANIES-DOCUMENTS-PAGE

    //USERS
    users: "nav.users",
    user: "nav.user",
    usersSearchPlaceholder: "nav.usersSearchPlaceholder",
    usersStatusActive: "nav.usersStatusActive",
    usersStatusDisable: "nav.usersStatusDisable",
    usersSortById: "nav.usersSortById",
    usersSortByName: "nav.usersSortByName",
    usersSortByPhone: "nav.usersSortByPhone",
    userDelete: "nav.userDelete",
    userBlock: "nav.userBlock",
    userUnlock: "nav.userUnlock",
    userDetails: "nav.userDetails",
    userCompaniesEmpty: "nav.userCompaniesEmpty",
    userBlocUser: "nav.userBlocUser",
    description: "nav.description",

    //USER-EDIT-PAGE
    firstNamePlaceHolder: "nav.firstNamePlaceHolder",
    firstNameRequired: "nav.firstNameRequired",
    lastNamePlaceHolder: "nav.lastNamePlaceHolder",
    lastNameRequired: "nav.lastNameRequired",
    surnamePlaceHolder: "nav.surnamePlaceHolder",
    phoneMax: "nav.phoneMax",
    userEdit: "nav.userEdit",
    userUpdated: "nav.userUpdated",
  },
  employeesPage: {
    employees: "employeesPage.employees",
    employeesSearchPlaceholder: "nav.employeesSearchPlaceholder",
    employee: "employeesPage.employee",
  },
  admin: {
    user: {
      indexPage: {
        title: "admin.user.indexPage.title",
        userSearchPlaceholder: "admin.user.indexPage.userSearchPlaceholder",
        userNumberTableTitle: "admin.user.indexPage.userNumberTableTitle",
        userTableTitle: "admin.user.indexPage.userTableTitle",
        userCompaniesCountTableTitle:
          "admin.user.indexPage.userCompaniesCountTableTitle",
        usersDataIsEmpty: "admin.user.indexPage.usersDataIsEmpty",
        usersAddDialogTitle: "admin.user.indexPage.usersAddDialogTitle",
      },
    },
  },
  adminPages: {
    account: {
      tabs: {
        general: "adminPages.account.tabs.general",
        notification: "adminPages.account.tabs.notification",
        security: "adminPages.account.tabs.security",
      }
    },
    loginpi: {
      numberOfDocument: "nav.numberOfDocument",
      documentDateCreate: "adminPages.loginpi.documentDateCreate",
    },
    userPage: {
      deleteWarningDescription: "adminPages.userPage.deleteWarningDescription",
      createForm: {
        createdSuccessful: "adminPages.userPage.createForm.createdSuccessful",
      },
    },
    payments: {
      title: "adminPages.payments.title",
      transactions: {
        title:  "adminPages.payments.transactions.title",
        table: {
          head: {
            id: "adminPages.payments.transactions.table.head.id",
            login: "adminPages.payments.transactions.table.head.login",
            amount: "adminPages.payments.transactions.table.head.amount",
            admin: "adminPages.payments.transactions.table.head.admin",
            status: "adminPages.payments.transactions.table.head.status",
            date: "adminPages.payments.transactions.table.head.date",
          }
        },
        status: {
          enroll: "adminPages.payments.transactions.status.enroll",
          failed: "adminPages.payments.transactions.status.failed",
          progress: "adminPages.payments.transactions.status.progress"
        }
      },
      filters: {
        placeHolder: "adminPages.payments.filters.placeHolder"
      },
      table: {
        head: {
          payment: "adminPages.payments.table.head.payment",
          paymentFrom: "adminPages.payments.table.head.paymentFrom",
          paymentTo: "adminPages.payments.table.head.paymentTo",
          sum: "adminPages.payments.table.head.sum",
          date: "adminPages.payments.table.head.date",
          osnd: "adminPages.payments.table.head.osnd",
        },
        empty: "adminPages.payments.table.empty",
        received: "adminPages.payments.table.received",
        registered: "adminPages.payments.table.registered",
        approved: "adminPages.payments.table.approved",
        date: "adminPages.payments.table.date",
      },
      form: {
        title: "adminPages.payments.form.title",
        errorPointsAmount: "adminPages.payments.form.errorPointsAmount",
        errorPayMethod: "adminPages.payments.form.errorPayMethod",
        errorTotalAmount: "adminPages.payments.form.errorTotalAmount",
        successEnrollPayment: "adminPages.payments.form.successEnrollPayment",
        successPartiallyEnrollPayment: "adminPages.payments.form.successPartiallyEnrollPayment",
        errorEnrollPayment: "adminPages.payments.form.errorEnrollPayment",
        amount: "adminPages.payments.form.amount",
        amountExceeded: "adminPages.payments.form.amountExceeded",
        distributeAmount: "adminPages.payments.form.distributeAmount",
        totalAmount: "adminPages.payments.form.totalAmount",
        amountTextFieldLabel: "adminPages.payments.form.amountTextFieldLabel",
        amountTextFieldPlaceholder: "adminPages.payments.form.amountTextFieldPlaceholder",
        steps: {
          payMethod: "adminPages.payments.form.steps.payMethod",
          points: "adminPages.payments.form.steps.points",
        }
      }
    },
    companiesPage: {
      companiesListEmpty: "adminPages.companiesPage.companiesListEmpty",
      edit: {
        success: "adminPages.companiesPage.edit.success"
      }
    },
    integration: {
      vchasno: {
        documentPageTitle: "adminPages.integration.vchasno.documentPageTitle",
        incomingDocumentPageTitle: "adminPages.integration.vchasno.incomingDocumentPageTitle",
        partners: "adminPages.integration.vchasno.partners",
        status: {
          status700: "adminPages.integration.vchasno.status.status700",
          status701: "adminPages.integration.vchasno.status.status701",
          status702: "adminPages.integration.vchasno.status.status702",
          status703: "adminPages.integration.vchasno.status.status703",
          status704: "adminPages.integration.vchasno.status.status704",
          status706: "adminPages.integration.vchasno.status.status706",
          status707: "adminPages.integration.vchasno.status.status707",
          status708: "adminPages.integration.vchasno.status.status708",
          status710: "adminPages.integration.vchasno.status.status710",
        },
        category: {
          category1: "adminPages.integration.vchasno.category.category1",
          category2: "adminPages.integration.vchasno.category.category2",
          category3: "adminPages.integration.vchasno.category.category3",
          category4: "adminPages.integration.vchasno.category.category4",
          category5: "adminPages.integration.vchasno.category.category5",
          category6: "adminPages.integration.vchasno.category.category6",
          category7: "adminPages.integration.vchasno.category.category7",
          category8: "adminPages.integration.vchasno.category.category8",
          category9: "adminPages.integration.vchasno.category.category9",
          category10: "adminPages.integration.vchasno.category.category10",
          category11: "adminPages.integration.vchasno.category.category11",
          category12: "adminPages.integration.vchasno.category.category12",
          category13: "adminPages.integration.vchasno.category.category13",
          category14: "adminPages.integration.vchasno.category.category14",
          category15: "adminPages.integration.vchasno.category.category15",
          category16: "adminPages.integration.vchasno.category.category16",
          category17: "adminPages.integration.vchasno.category.category17",
          category18: "adminPages.integration.vchasno.category.category18",
          category19: "adminPages.integration.vchasno.category.category19",
          category20: "adminPages.integration.vchasno.category.category20",
          category21: "adminPages.integration.vchasno.category.category21",
          category22: "adminPages.integration.vchasno.category.category22",
          category23: "adminPages.integration.vchasno.category.category23",
          category24: "adminPages.integration.vchasno.category.category24",
          category25: "adminPages.integration.vchasno.category.category25",
          category26: "adminPages.integration.vchasno.category.category26",
        },
        table: {
          listEmpty: "adminPages.integration.vchasno.table.listEmpty",
          date: "adminPages.integration.vchasno.table.date",
          document: "adminPages.integration.vchasno.table.document",
          partner: "adminPages.integration.vchasno.table.partner",
          number: "adminPages.integration.vchasno.table.number",
          status: "adminPages.integration.vchasno.table.status",
          category: "adminPages.integration.vchasno.table.category",
          companyName: "adminPages.integration.vchasno.table.companyName",
          companyEdrpou: "adminPages.integration.vchasno.table.companyEdrpou",
          documentNumber: "adminPages.integration.vchasno.table.documentNumber",
          amount: "adminPages.integration.vchasno.table.amount",
          actions: "adminPages.integration.vchasno.table.actions",
        },
      },
      novaposhta: {
        outgoing: {
          title: "adminPages.integration.novaposhta.outgoing.title"
        },
        table: {
          listEmpty: "adminPages.integration.novaposhta.table.listEmpty",
          number: "adminPages.integration.novaposhta.table.number",
          status: "adminPages.integration.novaposhta.table.status",
          createDate: "adminPages.integration.novaposhta.table.createDate",
          deliveryDate: "adminPages.integration.novaposhta.table.deliveryDate",
          sender: "adminPages.integration.novaposhta.table.sender",
          senderPhone: "adminPages.integration.novaposhta.table.senderPhone",
          recipient: "adminPages.integration.novaposhta.table.recipient",
          recipientPhone: "adminPages.integration.novaposhta.table.recipientPhone",
        },
        status: {
          status1: "adminPages.integration.novaposhta.status.status1",
          status2: "adminPages.integration.novaposhta.status.status2",
          status3: "adminPages.integration.novaposhta.status.status3",
          status4: "adminPages.integration.novaposhta.status.status4",
          status41: "adminPages.integration.novaposhta.status.status41",
          status5: "adminPages.integration.novaposhta.status.status5",
          status6: "adminPages.integration.novaposhta.status.status6",
          status7: "adminPages.integration.novaposhta.status.status7",
          status8: "adminPages.integration.novaposhta.status.status8",
          status9: "adminPages.integration.novaposhta.status.status9",
          status10: "adminPages.integration.novaposhta.status.status10",
          status11: "adminPages.integration.novaposhta.status.status11",
          status12: "adminPages.integration.novaposhta.status.status12",
          status101: "adminPages.integration.novaposhta.status.status101",
          status102: "adminPages.integration.novaposhta.status.status102",
          status103: "adminPages.integration.novaposhta.status.status103",
          status104: "adminPages.integration.novaposhta.status.status104",
          status105: "adminPages.integration.novaposhta.status.status105",
          status106: "adminPages.integration.novaposhta.status.status106",
          status111: "adminPages.integration.novaposhta.status.status111",
          status112: "adminPages.integration.novaposhta.status.status112",
        },
        shortStatus: {
          statusDone: "adminPages.integration.novaposhta.shortStatus.statusDone",
          statusInProgress: "adminPages.integration.novaposhta.shortStatus.statusInProgress",
          statusInDepartment: "adminPages.integration.novaposhta.shortStatus.statusInDepartment",
          statusError: "adminPages.integration.novaposhta.shortStatus.statusError",
          statusOpen: "adminPages.integration.novaposhta.shortStatus.statusOpen",
        }
      }
    },
    settings: {
      payment: {
        automatic: {
          title: "adminPages.settings.payment.automatic.title",
          company: "adminPages.settings.payment.automatic.company",
          lastUpdate: "adminPages.settings.payment.automatic.lastUpdate",
          form: {
            title: "adminPages.settings.payment.automatic.form.title",
            buttonTitle: "adminPages.settings.payment.automatic.form.buttonTitle",
            startDateTitle: "adminPages.settings.payment.automatic.form.startDateTitle",
            successAutoEnrollPayment: "adminPages.settings.payment.automatic.form.successAutoEnrollPayment",
            companyRequired: "adminPages.settings.payment.automatic.form.companyRequired",
            lastPaymentDateRequired: "adminPages.settings.payment.automatic.form.lastPaymentDateRequired"
          }
        }
      },
      integration: {
        googleDrive: "adminPages.settings.integration.googleDrive",
        googleDriveButtonConnect: "adminPages.settings.integration.googleDriveButtonConnect",
        googleDriveButtonDeleteAccount: "adminPages.settings.integration.googleDriveButtonDeleteAccount",
        warningDescription: "adminPages.settings.integration.warningDescription",
        vchasno: {
          title: "adminPages.settings.integration.vchasno.title",
          intervalSyncCheckboxTitle: "adminPages.settings.integration.vchasno.intervalSyncCheckboxTitle",
          intervalSyncTitle: "adminPages.settings.integration.vchasno.intervalSyncTitle",
          lastUpdate: "adminPages.settings.integration.vchasno.lastUpdate",
          successCreated: "adminPages.settings.integration.vchasno.successCreated",
          warningDescription: "adminPages.settings.integration.vchasno.warningDescription",
          createVchasnoConnectionFormTitle: "adminPages.settings.integration.vchasno.createVchasnoConnectionFormTitle",
          updateVchasnoConnectionFormTitle: "adminPages.settings.integration.vchasno.updateVchasnoConnectionFormTitle",
          apiKeyTextFieldLabel: "adminPages.settings.integration.vchasno.apiKeyTextFieldLabel",
        },
        novaposhta: {
          title: "adminPages.settings.integration.novaposhta.title",
          name: "adminPages.settings.integration.novaposhta.name",
          apiToken: "adminPages.settings.integration.novaposhta.apiToken",
          successCreated: "adminPages.settings.integration.novaposhta.successCreated",
        }
      }
    }
  },

  accountPage: {
    title: "accountPage.title",
    pageGeneral: "accountPage.pageGeneral",
    pageSecurity: "accountPage.security",

    general: {
      title: "accountPage.general.title",
      chooseImg: "accountPage.general.chooseImg",
      formId: "accountPage.general.formId",
      formLogin: "accountPage.general.formLogin",
      formName: "accountPage.general.formName",
      formEmail: "accountPage.general.formEmail",
      phoneNumber: "accountPage.general.phoneNumber",
      deleteAccount: "accountPage.general.deleteAccount",
      deleteAccountData: "accountPage.general.deleteAccountData",
    },

    security: {
      title: "accountPage.security.title",
      subheader: "accountPage.security.subheader",
      loginType: "accountPage.security.loginType",
      ipAddress: "accountPage.security.ipAddress",
      entryData: "accountPage.security.entryData",
    },
  },

  company: {
    edrpou: "company.edrpou",
    iban: "company.iban",
    mfo: "company.mfo",
    vat: "company.vat",
    headPosition: "company.headPosition",
    basis: "company.basis",
    legalAddress: "company.legalAddress",
    address: "company.address",
    totalDebt: 'company.totalDebt',
    budgetary:'company.budgetary',
    legal: "company.legal",
    users: {
      search: "company.users.search",
      error: "company.users.error",
      title: "company.users.title",
      number: "company.users.number",
      fio: "company.users.fio",
      email: "company.users.email",
      phone: "company.users.phone",
      status: "company.users.status",
      actions: "company.users.actions",
      post: "company.users.post",
      join: "company.users.join",
      form: {
        title: "company.users.form.title",
        button: "company.users.form.button",
        success: "company.users.form.success",
      }
    },
    novaposhta: {
      title: "company.novaposhta.title",
      type: {
        all: "company.novaposhta.type.all",
        incoming: "company.novaposhta.type.incoming",
        outgoing: "company.novaposhta.type.outgoing",
        archive: "company.novaposhta.type.archive",
      }
    }
  },

  loginpi: {
    uid: "loginpi.uid",
    balance: "loginpi.balance",
    tariff: "loginpi.tariff",
    description: "loginpi.description",
    dateOfRegistration: "loginpi.dateOfRegistration",
    payment: {
      title: "loginpi.payment.title",
      number: "loginpi.payment.number",
      date: "loginpi.payment.date",
      description: "loginpi.payment.description",
      sum: "loginpi.payment.sum",
      methodType: "loginpi.payment.methodType",
      extId: "loginpi.payment.extId",
    },
    fees: {
      title: "loginpi.fees.title",
      number: "loginpi.fees.number",
      date: "loginpi.fees.date",
      description: "loginpi.fees.description",
      sum: "loginpi.fees.sum",
      methodType: "loginpi.fees.methodType",
    }
  },

  formCompany: {
    edrpouLength: "formCompany.edrpouLength",
    edrpouSpecify: "formCompany.edrpouSpecify",
    edrpouWrong: "formCompany.edrpouWrong",
    nameCompany: "formCompany.nameCompany",
    nameCompanySpecify: "formCompany.nameCompanySpecify",
    ibanLength: "formCompany.ibanLength",
    ibanSpecify: "formCompany.ibanSpecify",
    ibanWrong: "formCompany.ibanWrong",
    legalAddressSpecify: "formCompany.legalAddressSpecify",
    basisSpecify: "formCompany.basisSpecify",
    companyIban: "formCompany.companyIban",
    addressMaches: "formCompany.addressMaches",
    budgetary: "formCompany.budgetary",
    headNameSpecify: "formCompany.headNameSpecify",
    headPositionSpecify: "formCompany.headPositionSpecify",
    mfoSpecifyMaxLength: "formCompany.mfoSpecifyMaxLength",
    taxLength: "formCompany.taxLength",
    taxSpecify: "formCompany.taxSpecify",
    factualAddress: "formCompany.factualAddress",
    editInformationCompany: "formCompany.editInformationCompany",
    editInformationHeadCompany: "formCompany.editInformationHeadCompany",
    mfoSpecify: "formCompany.mfoSpecify",
  },

  document: {
    typeDocument: "document.typeDocument",
    internetContract: "document.internetContract",
    nameDocument: "document.nameDocument",
    connectionContract: "document.connectionContract",
    provisionOfServicesContract: "document.provisionOfServicesContract",
    connectionBill: "document.connectionBill",
    documentFormat: "document.documentFormat",
    googleDrive: "document.googleDrive",
    downloadDocument: "document.downloadDocument",
    numberDocument: "document.numberDocument",
    maximumFile: "document.maximumFile",
    generateDocument: "document.generateDocument",
    errorParentDocument: "document.errorParentDocument",
    templateManualOptionsEmpty: "document.templateManualOptionsEmpty",
    pdf: "document.pdf",
    doc: "document.doc",
    docx: "document.docx",
    companySearch: "document.companySearch",
    selectCompanyError: "document.selectCompanyError",
    companyNotFound: "document.companyNotFound",
    startAction: "document.startAction",
    numberDoc: "document.numberDoc",
    selectDoc: "document.selectDoc",
    documentNotFount: "document.documentNotFount",
    listEmpty: "document.listEmpty",
    searchDoc: "document.searchDoc",
    numberInvoice: "document.dateIssue",
    dateIssue: "document.numberInvoice",
    billed: "document.billed",
    grid: "document.grid",
    description: "document.description",
    qty: "document.qty",
    unitPrice: "document.unitPrice",
    total: "document.total",
    subtotal: "document.subtotal",
    taxes: "document.taxes",
    notes: "document.notes",
    listsEmpty:'document.listsEmpty'
  },

  analytics: {
    index: "analytics.index",
    title: "analytics.title",
    totalDebt: "analytics.totalDebt",
    table: {
      number: "analytics.table.number",
      company: "analytics.table.company",
      edrpou: "analytics.table.edrpou",
      lastPayment: "analytics.table.lastPayment",
      countOfLoginPI: "analytics.table.countOfLoginPI",
      totalDebtSum: "analytics.table.totalDebtSum",
      countOfMonthDebt: "analytics.table.countOfMonthDebt",
    }
  },
  dashboard: {
    dashboard: "dashboard.dashboard",
    vehiclesDeviatedRoute: "dashboard.vehiclesDeviatedRoute",
    vehiclesErrors: "dashboard.vehiclesErrors",
    fleet: "dashboard.fleet",
    mapCannotLoaded: "dashboard.mapCannotLoaded",
    mapBoxApiKey: "dashboard.mapBoxApiKey",
    temperature: "dashboard.temperature",
    temperatureGood: "dashboard.temperatureGood",
    trackingNumberCreated: "dashboard.trackingNumberCreated",
    outForDelivery: "dashboard.outForDelivery",
    arrived: "dashboard.arrived",
    totalCompanies: "dashboard.totalCompanies",
    veryGood: "dashboard.veryGood",
    excellent: "dashboard.excellent",
    good: "dashboard.good",
    goodCondition: "dashboard.goodCondition",
    bad: "dashboard.bad",
    needsAttention: "dashboard.needsAttention",
    vehiclesCondition: "dashboard.vehiclesCondition",
    routeVehicles: "dashboard.routeVehicles",
    conditionTemperature: "dashboard.conditionTemperature",
    location: "dashboard.location",
    endingRoute: "dashboard.endingRoute",
    startingRoute: "dashboard.startingRoute",
    warnings: "dashboard.warnings",
    noWarnings: "dashboard.noWarnings",
    refrigeratorTemperature: "dashboard.refrigeratorTemperature",
    vehiclesOverview: "dashboard.vehiclesOverview",
    total: "dashboard.total",
    panel: "dashboard.panel",
    available: "dashboard.available",
    outService: "dashboard.outService",
    onRoute: "dashboard.onRoute",
    temperatureNotOptimal: "dashboard.temperatureNotOptimal",
    notResponding: "dashboard.notResponding",
    companiesCountWidgetTitle: "dashboard.companiesCountWidgetTitle",
    userCountWidgetTitle: "dashboard.userCountWidgetTitle",
    loginPICountWidgetTitle: "dashboard.loginPICountWidgetTitle",
    loginPICountNotActiveWidgetTitle:
      "dashboard.loginPICountNotActiveWidgetTitle",
  },

  error: {
    errorFind: "error.errorFind",
    errorSave: "error.errorSave",
    download: "error.download",
    errorUser: "error.errorUser",
  },
};
