import {lazy, Suspense} from 'react';
import type {RouteObject} from 'react-router';
import {Outlet} from 'react-router-dom';
import {Layout as AdminLayout} from 'src/layouts/admin/index';

const Dashboard = lazy(() => import('src/pages/admin/dashboard'));
const Analytics = lazy(() => import('src/pages/admin/analytics'));

//Account
const AccountPage = lazy(() => import('src/pages/admin/account'));

//Companies
const CompaniesPage = lazy(() => import('src/pages/admin/companies'));
const CompaniesDetailPage = lazy(() => import('src/pages/admin/companies/detail'));
const CompaniesEditPage = lazy(() => import('src/pages/admin/companies/edit'));

//Users
const UsersPage = lazy(() => import('src/pages/admin/users'));
const UsersDetailPage = lazy(() => import('src/pages/admin/users/detail'));
const UsersEditPage = lazy(() => import('src/pages/admin/users/edit'));

//Logins
const LoginspiPage = lazy(() => import('src/pages/admin/loginspi'));
const LoginPIDetailPage = lazy(() => import('src/pages/admin/loginspi/detail'));

//Documents
const DocumentsPage = lazy(() => import('src/pages/admin/documents'));

//Employees
const EmployeesPage = lazy(() => import('src/pages/admin/employees'));

//Payments
const PaymentsPage = lazy(() => import('src/pages/admin/payments'));
const PaymentDetailsPage = lazy(() => import('src/pages/admin/payments/detail'));

const VchasnoDocumentPage = lazy(() => import('src/pages/admin/integration/vchasno/documents'));

const VchasnoIncomingDocumentPage = lazy(() => import('src/pages/admin/integration/vchasno/incoming-documents'));

const NovaPoshtaOutgoingDocumentPage = lazy(() => import('src/pages/admin/integration/novaposhta/outgoing-documents'));
const NovaPoshtaIncomingDocumentPage = lazy(() => import('src/pages/admin/integration/novaposhta/incoming-documents'));
const NovaPoshtaArchiveDocumentPage = lazy(() => import('src/pages/admin/integration/novaposhta/archive-documents'));

const TemplatesPage = lazy(() => import('src/pages/admin/settings/templates'));

const DirectoryTypesPage = lazy(() => import('src/pages/admin/settings/directory'));

const VchasnoIntegrationPage = lazy(() => import('src/pages/admin/settings/integration/vchasno'));
const NovaPoshtaIntegrationPage = lazy(() => import('src/pages/admin/settings/integration/novaposhta'));

const OthersIntegrationPage = lazy(() => import('src/pages/admin/settings/integration/other'));
const MethodPaymentPage = lazy(() => import('src/pages/admin/settings/payment/method'));
const AutomaticPaymentPage = lazy(() => import('src/pages/admin/settings/payment/automatic'));

// @ts-ignore
export const adminRoutes: RouteObject[] = [
    {
        path: '/admin',
        element: (
            <AdminLayout>
                <Suspense>
                    <Outlet/>
                </Suspense>
            </AdminLayout>
        ),
        children: [
            {
                index: true,
                element: <Dashboard/>
            },
            {
                path: 'analytics',
                children: [
                    {
                        index: true,
                        element: <Analytics/>
                    },
                ]
            },
            {
                path: 'account',
                children: [
                    {
                        index: true,
                        element: <AccountPage/>
                    },
                ]
            },
            {
                path: 'companies',
                children: [
                    {
                        index: true,
                        element: <CompaniesPage/>
                    },
                    {
                        path: ':companiesId',
                        element: <CompaniesDetailPage/>
                    },
                    {
                        path: ':companiesId/edit',
                        element: <CompaniesEditPage/>
                    },
                ]
            },
            {
                path: 'users',
                children: [
                    {
                        index: true,
                        element: <UsersPage/>
                    },
                    {
                        path: ':usersId',
                        element: <UsersDetailPage/>
                    },
                    {
                        path: ':usersId/edit',
                        element: <UsersEditPage/>
                    },
                ]
            },
            {
                path: 'logins',
                children: [
                    {
                        index: true,
                        element: <LoginspiPage/>
                    },
                    {
                        path: ':loginPI',
                        element: <LoginPIDetailPage/>
                    },
                ]
            },
            {
                path: 'payments',
                children: [
                    {
                        index: true,
                        element: <PaymentsPage/>
                    },
                    {
                        path: ':technicalTransactionId/transactions',
                        element: <PaymentDetailsPage/>
                    }
                ]
            },
            {
                path: 'documents',
                children: [
                    {
                        index: true,
                        element: <DocumentsPage/>
                    }
                ]
            },
            {
                path: 'employees',
                children: [
                    {
                        index: true,
                        element: <EmployeesPage/>
                    },
                ]
            },
            {
                path: 'integration',
                children: [
                    {
                        path: "novaposhta",
                        children: [
                            {
                                path: 'outgoing-documents',
                                element: <NovaPoshtaOutgoingDocumentPage/>
                            },
                            {
                                path: 'incoming-documents',
                                element: <NovaPoshtaIncomingDocumentPage/>
                            },
                            {
                                path: 'archive-documents',
                                element: <NovaPoshtaArchiveDocumentPage/>
                            },
                        ]
                    },
                    {
                        path: "vchasno",
                        children: [
                            {
                                path: 'documents',
                                element: <VchasnoDocumentPage/>
                            },
                            {
                                path: 'incoming-documents',
                                element: <VchasnoIncomingDocumentPage/>
                            }
                        ]
                    },
                ]
            },
            {
                path: 'settings',
                children: [
                    {
                        path: 'templates',
                        element: <TemplatesPage/>
                    },
                    {
                        path: 'integration',
                        children: [
                            {
                                path: 'vchasno',
                                element: <VchasnoIntegrationPage/>
                            },
                            {
                                path: 'novaposhta',
                                element: <NovaPoshtaIntegrationPage/>
                            },
                            {
                                path: 'others',
                                element: <OthersIntegrationPage/>
                            }
                        ]
                    },
                    {
                        path: 'directory',
                        element: <DirectoryTypesPage/>
                    },
                    {
                        path: 'payment',
                        children: [
                            {
                                path: 'automatic',
                                element: <AutomaticPaymentPage/>
                            },
                            {
                                path: 'methods',
                                element: <MethodPaymentPage/>
                            }
                        ]
                    }
                ]
            },
        ]
    }
];
