import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Document} from "../../../types/document";

interface DocumentsState {
    isDocumentsLoading: boolean;
    documentsPage: DocumentsPage;
    documentsSearch: DocumentsSearch;
}

const initialState: DocumentsState = {
    isDocumentsLoading: false,
    documentsPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    documentsSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
    }
};

interface DocumentsPage {
    data: Document[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface DocumentsSearch {
    query: string;
    page: number;
    documentTypeId?: number;
    rowsPerPage: number;
}

interface DocumentsAction {
    data: Document[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetDocumentsAction = PayloadAction<DocumentsAction>;

type DeleteDocumentAction = PayloadAction<number>;

type DocumentsLoadingAction = PayloadAction<boolean>;

type FilterAction = PayloadAction<DocumentsSearch>;

const reducers = {
    onDocumentsLoadingChange(state: DocumentsState, action: DocumentsLoadingAction): void {
        state.isDocumentsLoading = action.payload;
    },

    onFilterChange(state: DocumentsState, action: FilterAction): void {
        state.documentsSearch = action.payload;
    },

    onDocuments(state: DocumentsState, action: GetDocumentsAction): void {
        state.documentsPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },

    onDeleteDocument(state: DocumentsState, action: DeleteDocumentAction): void {
        const documents = [...state.documentsPage.data];
        const index = documents.findIndex((document) => document.id === action.payload)
        if (index > -1) {
            documents.splice(index, 1);
        }
        state.documentsPage.data = documents;
    }
}


export const slice = createSlice({
    name: 'documentsList',
    initialState,
    reducers
});

export const {reducer} = slice;