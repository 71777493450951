import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Template} from "../../../types/template";

interface TemplatesState {
    isTemplatesLoading: boolean;
    templatesPage: TemplatesPage;
    templatesSearch: TemplatesSearch;
}

const initialState: TemplatesState = {
    isTemplatesLoading: false,
    templatesPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    templatesSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
    }
};

interface TemplatesPage {
    data: Template[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface TemplatesSearch {
    query: string;
    page: number;
    documentTypeId?: number;
    rowsPerPage: number;
}

interface TemplatesAction {
    data: Template[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetTemplatesAction = PayloadAction<TemplatesAction>;
type DeleteTemplatesAction = PayloadAction<number>;

type DocumentsLoadingAction = PayloadAction<boolean>;

type FilterAction = PayloadAction<TemplatesSearch>;

const reducers = {
    onTemplatesLoadingChange(state: TemplatesState, action: DocumentsLoadingAction): void {
        state.isTemplatesLoading = action.payload;
    },

    onFilterChange(state: TemplatesState, action: FilterAction): void {
        state.templatesSearch = action.payload;
    },
    onTemplates(state: TemplatesState, action: GetTemplatesAction): void {
        state.templatesPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onDeleteTemplate(state: TemplatesState, action: DeleteTemplatesAction): void {
        const templates = [...state.templatesPage.data];
        const index = templates.findIndex((template) => template.id === action.payload)
        if (index > -1) {
            templates.splice(index, 1);
        }
        state.templatesPage.data = templates;
    }
}


export const slice = createSlice({
    name: 'templatesList',
    initialState,
    reducers
});

export const {reducer} = slice;