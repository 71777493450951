import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    AnalyticsDebt,
    AnalyticsReportFilter,
    AnalyticsReportPage, TotalReportCharts,
    TotalReportStatistic
} from "../../../types/analytics";

const now = new Date();
const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

interface AnalyticsDeptState {
    isLoadingAnalyticsReport: boolean;
    totalReportStatistic: TotalReportStatistic;
    analyticsCharts: TotalReportCharts;
    analyticsReportPage: AnalyticsReportPage;
    analyticsReportFilter: AnalyticsReportFilter;
}

const initialState: AnalyticsDeptState = {
    isLoadingAnalyticsReport: false,
    totalReportStatistic: {
        totalDeposit: 0,
        totalDebt: 0,
        totalPayments: 0,
        totalFees: 0,
    },
    analyticsCharts: {
        fees: [],
        payments: []
    },
    analyticsReportPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    analyticsReportFilter: {
        startDate: firstDayOfMonth,
        endDate: now,
        providerEdrpou: "all",
        query: "",
        budgetId: 0,
        page: 0,
        size: 10,
        sortType: 1
    }
};

type GetAnalyticsDeptPageAction = PayloadAction<AnalyticsReportPage>;
type GetAnalyticsStatisticsAction = PayloadAction<TotalReportStatistic>;
type GetAnalyticsChartsAction = PayloadAction<TotalReportCharts>;

type LoadingAnalyticsReportAction = PayloadAction<boolean>;
type AnalyticsReportFilterAction = PayloadAction<AnalyticsReportFilter>;

const reducers = {
    onLoadingAnalyticsReportChange(state: AnalyticsDeptState, action: LoadingAnalyticsReportAction): void {
        state.isLoadingAnalyticsReport = action.payload;
    },

    onAnalyticsStatisticsChange(state: AnalyticsDeptState, action: GetAnalyticsStatisticsAction): void {
        state.totalReportStatistic = action.payload;
    },

    onAnalyticsChartsChange(state: AnalyticsDeptState, action: GetAnalyticsChartsAction): void {
        state.analyticsCharts = action.payload;
    },

    onAnalyticsReportFilterChange(state: AnalyticsDeptState, action: AnalyticsReportFilterAction): void {
        state.analyticsReportFilter = action.payload;
    },

    onAnalyticsReportPageChange(state: AnalyticsDeptState, action: GetAnalyticsDeptPageAction): void {
        state.analyticsReportPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
}


export const slice = createSlice({
    name: 'analyticsReportList',
    initialState,
    reducers
});

export const {reducer} = slice;