import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Employee} from "../../../types/employee";

interface EmployeesState {
    isLoadingEmployees: boolean;
    employeesPage: EmployeesPage;
    employeesSearch: EmployeesSearch;
}

const initialState: EmployeesState = {
    isLoadingEmployees: false,
    employeesPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    employeesSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
        sortBy: 'aid',
    }
};

interface EmployeesPage {
    data: Employee[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface EmployeesSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface EmployeesAction {
    data: Employee[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetEmployeesAction = PayloadAction<EmployeesAction>;

type LoadingEmployeesAction = PayloadAction<boolean>;

type FilterAction = PayloadAction<EmployeesSearch>;

const reducers = {
    onLoadingEmployeesChange(state: EmployeesState, action: LoadingEmployeesAction): void {
        state.isLoadingEmployees = action.payload;
    },

    onFilterChange(state: EmployeesState, action: FilterAction): void {
        state.employeesSearch = action.payload;
    },

    onEmployees(state: EmployeesState, action: GetEmployeesAction): void {
        state.employeesPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    }
}


export const slice = createSlice({
    name: 'employeesList',
    initialState,
    reducers
});

export const {reducer} = slice;