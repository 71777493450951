import {Partner} from "./partner";
import {SyncStatus} from "./sync-status";

export interface VchasnoInfo {
    id: number;
    partner: Partner;
    apiKey: string;
    syncStatus: SyncStatus;
    intervalSyncMin: number;
    lastSyncDate: string;
    updated: Date;
}

export interface VchasnoCreateRequest {
    partnerEdrpou: string;
    apiKey: string;
    intervalSyncMin?: number;
}


export interface VchasnoDocument {
    id: number;
    vchasnoId: string;
    partner: Partner;
    title: string;
    number: string;
    status: VchasnoDocumentStatus;
    category: VchasnoDocumentCategory;
    companyName: string;
    companyEdrpou: string;
    extension: string;
    amount: number;
    syncStatus: SyncStatus;
    url: string;
    dateCreated: string;
    created: string;
    updated: string;
}

export enum VchasnoDocumentStatus {
    DOCUMENT_UPLOADED_TO_SYSTEM = 7000,
    DOCUMENT_READY_FOR_SIGNING_AND_SENDING = 7001,
    DOCUMENT_SENT_TO_COUNTERPARTY_FOR_FIRST_SIGNATURE = 7002,
    DOCUMENT_IS_PARTIALLY_SIGNED_BY_COUNTERPARTY = 7003,
    DOCUMENT_AWAITING_COUNTERPARTY_SIGNATURE = 7004,
    DOCUMENT_IS_REJECTED_BY_COUNTERPARTY = 7006,
    DOCUMENT_IS_PARTIALLY_SIGNED_BY_SIGNATORIES = 7007,
    DOCUMENT_IS_SIGNED = 7008,
    DOCUMENT_SENT_TO_SIGNATORIES = 7010
}

export enum VchasnoDocumentCategory {
    TYPE_EMPTY = 0,
    ACT_OF_RENDERED_SERVICES = 1,
    INVOICE = 2,
    CONTRACT = 3,
    ADDITIONAL_AGREEMENT_TO_CONTRACT = 4,
    CONSIGNMENT_NOTE = 5,
    TTN = 6,
    EDI_DOCUMENTS = 7,
    POWER_OF_ATTORNEY = 8,
    SPECIFICATION = 9,
    STATEMENT = 10,
    ACT_OF_ACCEPTANCE_AND_TRANSFER = 11,
    RETURN = 12,
    ORDER = 13,
    ADDITIONAL = 14,
    OTHER = 15,
    ACT_OF_RECONCILIATION = 16,
    PROTOCOL_OF_DISAGREEMENTS = 17,
    REPORT = 18,
    LETTER = 19,
    PROTOCOL = 20,
    COMMAND = 21,
    ACT_OF_RETURN = 22,
    CALCULATION_OF_ADJUSTMENT = 23,
    ACT_OF_ADJUSTMENT = 24,
    LETTER_OF_GUARANTEE = 25,
    ACCOUNTING_CERTIFICATE = 26,
}

export enum VchasnoDocumentType {
    SOURCE_DOCUMENT = 1,
    INCOMING_DOCUMENT
}


export interface VchasnoDocumentsFilter {
    query?: string;
    partnerEdrpou?: string;
    type: VchasnoDocumentType;
    page?: number;
    rowsPerPage?: number;
    sortBy?: string;
}