import type {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';
import {withAuthGuard} from 'src/hocs/with-auth-guard';
import {useSettings} from 'src/hooks/use-settings';
import {HorizontalLayout} from "../main/horizontal-layout";
import {VerticalLayout} from "../main/vertical-layout";
import {useAdminSections} from "./config";

interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useAdminSections();

  if (settings.layout === 'horizontal') {
    return (
      <HorizontalLayout
        sections={sections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={sections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node
};
