import type {FC, ReactNode} from 'react';
import {createContext, useCallback, useEffect, useReducer} from 'react';
import PropTypes from 'prop-types';
import {authApi} from 'src/api/auth';
import type {User} from 'src/types/user';
import {Issuer} from 'src/utils/auth';
import {Admin} from "../../types/admin";

const STORAGE_ACCESS_KEY = 'accessToken';
const STORAGE_REFRESH_KEY = 'refreshToken';

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  admin: Admin | null;
}

type JWTObject = {
  role: string,
  email: string,
  login: string,
  userId: number,
  abillsId: number,
  iat: number,
  exp: number
};

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  SIGN_IN = 'SIGN_IN',
  SIGN_UP = 'SIGN_UP',
  SIGN_OUT = 'SIGN_OUT'
}

type InitializeAction = {
  type: ActionType.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    admin: Admin | null;
  };
};

type SignInAction = {
  type: ActionType.SIGN_IN;
  payload: {
    user: User | null;
    admin: Admin | null;
  };
};

type SignUpAction = {
  type: ActionType.SIGN_UP;
  payload: {
    user: User | null;
    admin: Admin | null;
  };
};

type SignOutAction = {
  type: ActionType.SIGN_OUT;
};

type Action =
  | InitializeAction
  | SignInAction
  | SignUpAction
  | SignOutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  admin: null,
};

const handlers: Record<ActionType, Handler> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, admin } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      admin
    };
  },
  SIGN_IN: (state: State, action: SignInAction): State => {
    const { user, admin } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      admin
    };
  },
  SIGN_UP: (state: State, action: SignUpAction): State => {
    const { user, admin } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      admin
    };
  },
  SIGN_OUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    admin: null
  })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

export interface AuthContextType extends State {
  issuer: Issuer.JWT;
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, fullName: string, phone:string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextType>({
  ...initialState,
  issuer: Issuer.JWT,
  signIn: () => Promise.resolve(),
  signUp: () => Promise.resolve(),
  signOut: () => Promise.resolve()
});

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const getJwt = (accessToken: string) => {
    const [encodedHeader, encodedPayload] = accessToken.split('.');
    const header = JSON.parse(atob(encodedHeader));
    const payload: JWTObject = JSON.parse(atob(encodedPayload));
    const now = new Date();

    if (now < header.expiresIn) {
      throw new Error('Expired token');
    }
    return payload;
  }

  const initialize = useCallback(
    async (): Promise<void> => {
      try {

        const accessToken = window.localStorage.getItem(STORAGE_ACCESS_KEY);
        if (accessToken) {
          const jwt = getJwt(accessToken);

          if (jwt.role === 'ROLE_USER') {
            const user = await authApi.meUser({userId: jwt.userId})
            dispatch({
              type: ActionType.INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: user,
                admin: null,
              }
            });
          }
          if (jwt.role === 'ROLE_ADMIN' || jwt.role === 'ROLE_SUPER_ADMIN') {
            const admin = await authApi.meAdmin()
            dispatch({
              type: ActionType.INITIALIZE,
              payload: {
                isAuthenticated: true,
                user: null,
                admin: admin,
              }
            });
          }
        } else {
          dispatch({
            type: ActionType.INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
              admin: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: ActionType.INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
            admin: null
          }
        });
      }
    },
    [dispatch]
  );

  useEffect(
    () => {
      initialize();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const signIn = useCallback(
    async (username: string, password: string): Promise<void> => {
      const { accessToken, refreshToken } = await authApi.signIn({ username, password });

      localStorage.setItem(STORAGE_ACCESS_KEY, accessToken);
      localStorage.setItem(STORAGE_REFRESH_KEY, refreshToken);
      const jwt = getJwt(accessToken);

      if (jwt.role === 'ROLE_USER') {
        const user = await authApi.meUser({userId: jwt.userId})
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user: user,
            admin: null,
          }
        });
      }
      if (jwt.role === 'ROLE_ADMIN' || jwt.role === 'ROLE_SUPER_ADMIN') {
        const admin = await authApi.meAdmin()
        dispatch({
          type: ActionType.SIGN_IN,
          payload: {
            user: null,
            admin: admin,
          }
        });
      }
    },
    [dispatch]
  );

  const signUp = useCallback(
    async (email: string, fullName: string, phone: string,  password: string): Promise<void> => {
      await authApi.signUp({ email, fullName, phone, password })
          .then(_ => authApi.signIn({username: email, password})
              .then(({accessToken, refreshToken}) => {
                localStorage.setItem(STORAGE_ACCESS_KEY, accessToken);
                localStorage.setItem(STORAGE_REFRESH_KEY, refreshToken);
                const jwt = getJwt(accessToken);
                if (jwt.role === 'ROLE_USER') {
                  authApi.meUser({userId: jwt.userId})
                      .then((user) => {
                        dispatch({
                          type: ActionType.SIGN_IN,
                          payload: {
                            user: user,
                            admin: null,
                          }
                        });
                      })
                }
                if (jwt.role === 'ROLE_ADMIN' || jwt.role === 'ROLE_SUPER_ADMIN') {
                  authApi.meAdmin()
                      .then((admin) => {
                        dispatch({
                          type: ActionType.SIGN_IN,
                          payload: {
                            user: null,
                            admin: admin,
                          }
                        });
                      })
                }
              })
          );
    },
    [dispatch]
  );

  const signOut = useCallback(
    async (): Promise<void> => {
      localStorage.removeItem(STORAGE_ACCESS_KEY);
      dispatch({ type: ActionType.SIGN_OUT });
    },
    [dispatch]
  );

  return (
    <AuthContext.Provider
      value={{
        ...state,
        issuer: Issuer.JWT,
        signIn,
        signUp,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const AuthConsumer = AuthContext.Consumer;
