import {tokens} from "../tokens";

export const ua = {
  //HELP
  [tokens.help.successSendMessage]: "Повідомлення відправлено",
  //COMMON
  [tokens.common.billing.title]: "Abills",
  [tokens.common.billing.open]: "Відкрити в Abills",
  [tokens.common.dueDate]: "Due date",
  [tokens.common.currency]: "грн",
  [tokens.common.download]: "Завантажити",
  [tokens.common.delete]: "Видалити",
  [tokens.common.warning]: "warning",
  [tokens.common.success]: "success",
  [tokens.common.languageChanged]: "Se ha cambiado el idioma",
  [tokens.common.wrong]: "Something went wrong!",
  [tokens.common.number]: "№",
  [tokens.common.notifications]: "Сповіщення",
  [tokens.common.next]: "Далі",
  [tokens.common.numberDoc]: "Номер",
  [tokens.common.date]: "Дата",
  [tokens.common.size]: "Розмір",
  [tokens.common.actions]: "Дії",
  [tokens.common.action]: "Дія",
  [tokens.common.validUntil]: "Діє до",
  [tokens.common.yes]: "Так",
  [tokens.common.no]: "Ні",
  [tokens.common.submit]: "Додати",
  [tokens.common.export]: "Export",
  [tokens.common.addNewType]: "Додано новий тип",
  [tokens.common.cancel]: "Скасувати",
  [tokens.common.generate]: "Згенерувати",
  [tokens.common.comment]: "Коментар",
  [tokens.common.sortBy]: "Сортувати за",
  [tokens.common.search]: "Пошук",
  [tokens.common.actionFrom]: "Від",
  [tokens.common.actionTo]: "До",
  [tokens.common.phone]: "Телефон",
  [tokens.common.status]: "Статус",
  [tokens.common.fio]: "ПІБ",
  [tokens.common.etc]: "ІПН",
  [tokens.common.edrpou]: "ЄДРПОУ",
  [tokens.common.companyIban]: "IBAN",
  [tokens.common.regulations]: "Статут",
  [tokens.common.positionWork]: "Посада",
  [tokens.common.headName]: "Керівник",
  [tokens.common.stepHead]: "Керівник",
  [tokens.common.client]: "Клієнт",
  [tokens.common.director]: "Директор",
  [tokens.common.doctor]: "Головний лікар",
  [tokens.common.company]: "Компанія",
  [tokens.common.companyName]: "Назва компанії",
  [tokens.common.docExchange]: "Система обміну документами",
  [tokens.common.position]: "Положення",
  [tokens.common.order]: "Наказ",
  [tokens.common.law]: "Закон",
  [tokens.common.powerOfAttorney]: "Довіреність",
  [tokens.common.contract]: "Договір",
  [tokens.common.document]: "Документ",
  [tokens.common.manualOptions]: "Ручні параметри",
  [tokens.common.selectUser]: "Спочатку виберіть користувача!",
  [tokens.common.userNotFound]: "Користувача не знайдено",
  [tokens.common.enterValue]: "Введіть ПІБ aбо email",
  [tokens.common.addContact]: "Додати контакт",
  [tokens.common.addContactNew]: "Додано новий контакт",
  [tokens.common.contact]: "Контакт",
  [tokens.common.contacts]: "Контакти",
  [tokens.common.typeContact]: "Тип контакта",
  [tokens.common.typeContacts]: "Типи контактів",
  [tokens.common.addTypeContact]: "Додати тип контакту",
  [tokens.common.contactMissing]: "Контакти відсутні",
  [tokens.common.typeTemplate]: "Тип шаблону",
  [tokens.common.template]: "Шаблон",
  [tokens.common.templates]: "Шаблони",
  [tokens.common.searchTemplate]: "Пошук шаблону",
  [tokens.common.typeDoc]: "Типи документів",
  [tokens.common.addTypeDoc]: "Додати тип документу",
  [tokens.common.typeExchangeDoc]: "Типи обміну документами",
  [tokens.common.post]: "Посада",
  [tokens.common.addExchangeDoc]: "Додати обміну документу",
  [tokens.common.addPost]: "Додати посаду",
  [tokens.common.warningTitle]: "Попередження",
  [tokens.common.warningDescription]:
    "Ви впевнені, що хочете видалити тип? Під час видалення можуть пошкодитись важливі дані!",
  [tokens.common.created]: "Створенно",
  [tokens.common.version]: "Версія",
  [tokens.common.generated]: "Створений",
  [tokens.common.listEmpty]: "Список порожній",
  [tokens.common.listTemplatesEmpty]: "Список шаблонів порожній",
  [tokens.common.typeName]: "Назва типу",
  [tokens.common.showSelected]: "Показувати при виборі",
  [tokens.common.attachDocuments]: "Можуть підкріплюватись документи",
  [tokens.common.googleDrive]: "Google диск",
  //NAV
  [tokens.nav.academy]: "Academia",
  [tokens.nav.auth]: "Autenticación",
  [tokens.nav.fileManager]: "Administrador de Archivos",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.browse]: "Buscar",
  [tokens.nav.calendar]: "Calendario",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.contact]: "Contacto",
  [tokens.nav.course]: "Curso",
  [tokens.nav.create]: "Crear",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.postCreate]: "Crear Articulo",
  [tokens.nav.mail]: "Correo",
  [tokens.nav.customers]: "Clientes",
  [tokens.nav.postDetails]: "Detalles del Articulo",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Fuente social",
  [tokens.nav.finance]: "Finanzas",
  [tokens.nav.invoiceList]: "Facturas",
  [tokens.nav.filter]: "Фільтр",
  [tokens.nav.resetPassword]: "Restablecer Contraseña",
  [tokens.nav.forgotPassword]: "Recuperar Contraseña",
  [tokens.nav.socialMedia]: "Redes Sociales",
  [tokens.nav.verifyCode]: "Verificar Código",
  [tokens.nav.postList]: "Lista de Articulos",
  [tokens.nav.jobList]: "Listado de Trabajos",
  [tokens.nav.list]: "Lista",
  [tokens.nav.logistics]: "Logística",
  [tokens.nav.pricing]: "Precios",
  [tokens.nav.productList]: "Productos",
  [tokens.nav.profile]: "Perfil",
  [tokens.nav.orderList]: "Pedidos",
  [tokens.nav.fleet]: "Parque",
  [tokens.nav.pages]: "Páginas",
  [tokens.nav.checkout]: "Pago",
  [tokens.nav.management]: "Gestión",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.payments]: "Оплати",
  [tokens.nav.payment]: "Оплата",
  [tokens.nav.paymentMethod]: "Методи",
  [tokens.nav.main]: "Основне",
  [tokens.nav.general]: "Загальне",
  [tokens.nav.overview]: "Дашборд",
  [tokens.nav.account]: "Акаунт",
  [tokens.nav.logs]: "Журнал",
  [tokens.nav.templates]: "Шаблони",
  [tokens.nav.integration]: "Інтеграції",
  [tokens.nav.vchasnoDocument]: "Вихідні документи",
  [tokens.nav.vchasnoIncomingDocument]: "Вхідні документи",
  [tokens.nav.novaPoshtaOuthoingDocument]: "Віхидні",
  [tokens.nav.novaPoshtaIncomingDocument]: "Вхідні",
  [tokens.nav.novaPoshtaArchiveDocument]: "Архівовані",
  [tokens.nav.services]: "Сервіси",
  [tokens.nav.register]: "Реєстрація",
  [tokens.nav.logout]: "Вийти",
  [tokens.nav.setting]: "Налаштування",
  [tokens.nav.status]: "Статус",
  [tokens.nav.edit]: "Змінити",
  [tokens.nav.others]: "Інші",
  [tokens.nav.vchasno]: "Вчасно",
  [tokens.nav.novaposhta]: "Нова пошта",
  [tokens.nav.help]: "Вказати на помилку",
  [tokens.nav.types]: "Довідники",
  [tokens.nav.directory]: "Довідники",
  [tokens.nav.phone]: "Телефон",
  [tokens.nav.phoneRequired]: "Потрібно ввести номер телефону",
  [tokens.nav.email]: "Електронна пошта",
  [tokens.nav.emailValid]: "Електронна пошта має бути дійсною",
  [tokens.nav.emailRequired]: "Потрібно ввести електронну пошту",
  [tokens.nav.actions]: "Дії",
  [tokens.nav.delete]: "Видалити",
  [tokens.nav.update]: "Оновити",
  [tokens.nav.updateDone]: "Успішно оновлено",
  [tokens.nav.close]: "Close",
  [tokens.nav.sortBy]: "Сортувати за",
  [tokens.nav.generation]: "Згенерувати",
  [tokens.nav.download]: "Завантажити",
  [tokens.nav.downloadTemplate]: "Завантажити шаблон",
  [tokens.nav.paymentAutomatic]: "Автоматична оплата",
  [tokens.nav.all]: "Усього",
  [tokens.nav.further]: "Далі",
  [tokens.nav.analytics]: "Аналітика",
  [tokens.nav.number]: "№",
  [tokens.nav.labelRowsPerPage]: "Рядків на сторінці:",
  [tokens.nav.approved]: "Підтверджено",
  [tokens.nav.notApproved]: "Не підтверджено",
  [tokens.nav.open]: "Открыть в билинге",
  [tokens.nav.username]: "Ім'я користувача",
  [tokens.nav.usernameRequired]: "Потрібно ввести ім'я користувача",
  [tokens.nav.password]: "Пароль",
  [tokens.nav.passwordRequired]: "Потрібно ввести пароль",
  [tokens.nav.doNotAccount]: "Немає акаунту? ",
  [tokens.nav.alreadyHaveAccount]: "Вже є аккаунт?",
  [tokens.nav.login]: "Увійти",
  [tokens.nav.authorization]: "Вхід",
  [tokens.nav.loginTitle]: "Логін",
  [tokens.nav.loginsTitle]: "Логіни",
  [tokens.nav.mailAddress]: "Поштова адреса",
  [tokens.nav.fioRequired]: "Потрібно ввести ПІБ",
  [tokens.nav.documents]: "Документи",
  [tokens.nav.sentDocuments]: "Відправлені документи",
  [tokens.nav.settings]: "Налаштування",
  [tokens.nav.employees]: "Співробітники",
  [tokens.nav.provider]: "Провайдер",
  [tokens.nav.financing]: "Фінансування",
  [tokens.nav.basis]: "Підстава",
  [tokens.nav.connectionPoints]: "Точки підключення",
  [tokens.nav.connectionPointsNot]: "Точки підключення відсутні",
  [tokens.nav.details]: "Деталі",
  [tokens.nav.seeMore]: "Дізнатися більше",
  [tokens.nav.balance]: "Баланс",
  [tokens.nav.numberOfDocument]: "№ договору",
  [tokens.nav.notifications]: "Сповіщення",
  [tokens.nav.notificationsEmpty]: "Немає повідомлень",
  [tokens.nav.notificationsMarkAsRead]: "Позначити як прочитанні",
  [tokens.nav.loginPIPage]: "Точки підключення",
  //LOGIN
  [tokens.nav.loginActive]: "Активний",
  [tokens.nav.loginDisable]: "Неактивний",
  [tokens.nav.loginAddress]: "Адреса підключення",
  //LOGINS-PAGE
  [tokens.nav.loginsSearchPlaceholder]: "Пошук точки підключення",
  [tokens.nav.companyTableTitle]: "Організація",
  //COMPANIES
  [tokens.nav.searchCompanies]: "Search companies",
  [tokens.nav.companies]: "Список організацій",
  [tokens.nav.companiesSearchPlaceholder]: "Пошук організацій",
  [tokens.nav.companiesSortById]: "Сортувати за ідентифікатором",
  [tokens.nav.companiesSortByName]: "Сортувати по імені",
  [tokens.nav.companiesSortByProviderName]: "Сортувати по імені провайдера",
  [tokens.nav.companyConfirmed]: "Підтверджено",
  [tokens.nav.companyDetails]: "Подробиці",
  [tokens.nav.companyNotConfirmed]: "Не підтверджено",
  [tokens.nav.counterparty]: "Контрагент",
  [tokens.nav.companyType]: "Тип компанії",
  [tokens.nav.dateOfRegistration]: "Дата реєстрації",
  [tokens.nav.companyBudgetary]: "Компанія є бюджетною",
  [tokens.nav.companyPrivate]: "Компанія є приватною",
  [tokens.nav.awaitingConfirmation]: "Компанії очікують підтвердження",
  [tokens.nav.addCompany]: "Додати компанію",
  //USERS
  [tokens.nav.users]: "Користувачі",
  [tokens.nav.user]: "Користувач",
  [tokens.nav.usersSearchPlaceholder]: "Пошук користувачів",
  [tokens.nav.usersStatusActive]: "Активний",
  [tokens.nav.usersStatusDisable]: "Заблокований",
  [tokens.nav.usersSortById]: "Сортувати за ідентифікатором",
  [tokens.nav.usersSortByName]: "Сортувати по імені",
  [tokens.nav.usersSortByPhone]: "Сортувати за номером телефону",
  [tokens.nav.userDelete]: "Видалити акаунт",
  [tokens.nav.userBlock]: "Заблокувати",
  [tokens.nav.userBlocUser]: "Блокування акаунту",
  [tokens.nav.description]:
    "Ви впевнені, що хочете заблокувати акаунт користувача? Після блокування користувач не зможе використовувати платформу",
  [tokens.nav.userUnlock]: "Розблокувати",
  [tokens.nav.userDetails]: "Подробиці",
  [tokens.nav.userCompaniesEmpty]: "Користувач не має компаній",
  [tokens.nav.userEdit]: "Редагування користувача",
  //USER-INDEX-PAGE
  [tokens.admin.user.indexPage.title]: "Користувачі",
  [tokens.admin.user.indexPage.userSearchPlaceholder]: "Пошук користувачів",
  [tokens.admin.user.indexPage.userNumberTableTitle]: "№",
  [tokens.admin.user.indexPage.userCompaniesCountTableTitle]: "Організацій",
  [tokens.admin.user.indexPage.userTableTitle]: "Користувач",
  [tokens.admin.user.indexPage.usersDataIsEmpty]:
    "Список користувачів порожній",
  [tokens.admin.user.indexPage.usersAddDialogTitle]: "Додати користувача",
  //USER-EDIT-PAGE
  [tokens.nav.firstNamePlaceHolder]: "Ім'я",
  [tokens.nav.firstNameRequired]: "Потрібно вказати прізвище",
  [tokens.nav.lastNamePlaceHolder]: "Прізвище",
  [tokens.nav.lastNameRequired]: "Потрібно вказати ім'я",
  [tokens.nav.surnamePlaceHolder]: "По-батькові",
  [tokens.nav.userEdit]: "Редагування користувача",
  [tokens.nav.userUpdated]: "Інформація про користувача оновлена",

  [tokens.employeesPage.employees]: "Співробітники",
  [tokens.employeesPage.employeesSearchPlaceholder]: "Пошук співробітників",
  [tokens.employeesPage.employee]: "Співробітник",

  //ADMIN PAGE

  //Account
  [tokens.adminPages.account.tabs.general]: "Загальний",
  [tokens.adminPages.account.tabs.notification]: "Сповіщення",
  [tokens.adminPages.account.tabs.security]: "Безпека",

  [tokens.adminPages.loginpi.documentDateCreate]: "Дата підписання",
  [tokens.adminPages.userPage.createForm.createdSuccessful]: "Створення користувача успішно",
  [tokens.adminPages.userPage.deleteWarningDescription]:
      "Ви впевнені, що хочете видалити користувача? При видаленні користувача, будуть так же видаленні всі компанії й документи",
  [tokens.adminPages.companiesPage.companiesListEmpty]: "Список компаній порожній",
  [tokens.adminPages.companiesPage.edit.success]: "Успішне оновлення компанії",

  [tokens.adminPages.payments.title]: "Оплати",
  [tokens.adminPages.payments.transactions.title]: "Транзакції",
  [tokens.adminPages.payments.transactions.table.head.id]: "№",
  [tokens.adminPages.payments.transactions.table.head.login]: "Точка підключення",
  [tokens.adminPages.payments.transactions.table.head.amount]: "Сума",
  [tokens.adminPages.payments.transactions.table.head.status]: "Статус",
  [tokens.adminPages.payments.transactions.table.head.admin]: "Адміністратор",
  [tokens.adminPages.payments.transactions.table.head.date]: "Дата",
  [tokens.adminPages.payments.transactions.status.enroll]: "Зареєстровано",
  [tokens.adminPages.payments.transactions.status.failed]: "Помилка",
  [tokens.adminPages.payments.transactions.status.progress]: "Зараховується",
  [tokens.adminPages.payments.filters.placeHolder]: "Пошук платежу",
  [tokens.adminPages.payments.table.head.payment]: "Платіж",
  [tokens.adminPages.payments.table.head.paymentFrom]: "Платник",
  [tokens.adminPages.payments.table.head.paymentTo]: "Отримувач",
  [tokens.adminPages.payments.table.head.sum]: "Сума",
  [tokens.adminPages.payments.table.head.date]: "Дата",
  [tokens.adminPages.payments.table.head.osnd]: "Osnd",
  [tokens.adminPages.payments.table.empty]: "Список порожній",
  [tokens.adminPages.payments.table.received]: "Платіж отримано",
  [tokens.adminPages.payments.table.registered]: "Платіж зараховано",
  [tokens.adminPages.payments.table.approved]: "Платіж підтверджено",
  [tokens.adminPages.payments.table.date]: "Дата",
  [tokens.adminPages.payments.form.title]: "Додати оплату",
  [tokens.adminPages.payments.form.errorPointsAmount]: "Розподілена сума більша за загальну",
  [tokens.adminPages.payments.form.errorTotalAmount]: "Розподілена сума менша за загальну",
  [tokens.adminPages.payments.form.amount]: "Сума",
  [tokens.adminPages.payments.form.amountExceeded]: "Сума більша за загальну",
  [tokens.adminPages.payments.form.distributeAmount]: "Розподілена сума",
  [tokens.adminPages.payments.form.totalAmount]: "Загальна сума",
  [tokens.adminPages.payments.form.amountTextFieldLabel]: "Сума зарахування",
  [tokens.adminPages.payments.form.amountTextFieldPlaceholder]: "Вкажіть суму зарахованая",
  [tokens.adminPages.payments.form.successEnrollPayment]: "Платіж успішно зарахований",
  [tokens.adminPages.payments.form.successPartiallyEnrollPayment]: "Платіж частково зарахований",
  [tokens.adminPages.payments.form.errorEnrollPayment]: "Платіж не зарахований",
  [tokens.adminPages.payments.form.steps.payMethod]: "Метод оплати",
  [tokens.adminPages.payments.form.steps.points]: "Точки підключення",



  [tokens.loginpi.uid]: "UUID",
  [tokens.loginpi.balance]: "Баланс",
  [tokens.loginpi.tariff]: "Тариф",
  [tokens.loginpi.description]: "Коментар",
  [tokens.loginpi.dateOfRegistration]: "Дата реєстрації",
  [tokens.loginpi.payment.title]: "Оплати",
  [tokens.loginpi.payment.number]: "Номер",
  [tokens.loginpi.payment.date]: "Дата",
  [tokens.loginpi.payment.description]: "Опис",
  [tokens.loginpi.payment.sum]: "Сума",
  [tokens.loginpi.payment.methodType]: "Тип оплати",
  [tokens.loginpi.payment.extId]: "EXT ID",

  [tokens.loginpi.fees.title]: "Зняття коштів",
  [tokens.loginpi.fees.number]: "Номер",
  [tokens.loginpi.fees.date]: "Дата",
  [tokens.loginpi.fees.description]: "Опис",
  [tokens.loginpi.fees.sum]: "Сума",
  [tokens.loginpi.fees.methodType]: "Тип оплати",


  [tokens.adminPages.integration.vchasno.documentPageTitle]: "Вихідні документи",
  [tokens.adminPages.integration.vchasno.incomingDocumentPageTitle]: "Вхідні документи",
  [tokens.adminPages.integration.vchasno.partners]: "Контрагенти",
  [tokens.adminPages.integration.vchasno.status.status700]: "Завантажений у систему",
  [tokens.adminPages.integration.vchasno.status.status701]: "Готовий для підпису і надсилання",
  [tokens.adminPages.integration.vchasno.status.status702]: "Надісланий контрагенту на перший підпис",
  [tokens.adminPages.integration.vchasno.status.status703]: "Підписаний не всіма підписами, що необхідні зі сторони власника",
  [tokens.adminPages.integration.vchasno.status.status704]: "Очікує підпис контрагента",
  [tokens.adminPages.integration.vchasno.status.status706]: "Відхілений",
  [tokens.adminPages.integration.vchasno.status.status707]: "Підписаний не всіма підписами, що необхідні зі сторони отримувача",
  [tokens.adminPages.integration.vchasno.status.status708]: "Підписаний всіма",
  [tokens.adminPages.integration.vchasno.status.status710]: "Надісланий підписантам",


  [tokens.adminPages.integration.vchasno.category.category1]: "Акт наданих послуг",
  [tokens.adminPages.integration.vchasno.category.category2]: "Рахунок",
  [tokens.adminPages.integration.vchasno.category.category3]: "Договір",
  [tokens.adminPages.integration.vchasno.category.category4]: "Додаткова угода до договору",
  [tokens.adminPages.integration.vchasno.category.category5]: "Видаткова накладна",
  [tokens.adminPages.integration.vchasno.category.category6]: "ТТН",
  [tokens.adminPages.integration.vchasno.category.category7]: "ЕДІ документи",
  [tokens.adminPages.integration.vchasno.category.category8]: "Довіреність",
  [tokens.adminPages.integration.vchasno.category.category9]: "Специфікація",
  [tokens.adminPages.integration.vchasno.category.category10]: "Заява",
  [tokens.adminPages.integration.vchasno.category.category11]: "Акт приймання-передачі",
  [tokens.adminPages.integration.vchasno.category.category12]: "Повернення",
  [tokens.adminPages.integration.vchasno.category.category13]: "Замовлення",
  [tokens.adminPages.integration.vchasno.category.category14]: "Додаток",
  [tokens.adminPages.integration.vchasno.category.category15]: "Інший",
  [tokens.adminPages.integration.vchasno.category.category16]: "Акт звіряння",
  [tokens.adminPages.integration.vchasno.category.category17]: "Протокол розбіжностей",
  [tokens.adminPages.integration.vchasno.category.category18]: "Звіт",
  [tokens.adminPages.integration.vchasno.category.category19]: "Лист",
  [tokens.adminPages.integration.vchasno.category.category20]: "Протокол",
  [tokens.adminPages.integration.vchasno.category.category21]: "Наказ",
  [tokens.adminPages.integration.vchasno.category.category22]: "Акт повернення",
  [tokens.adminPages.integration.vchasno.category.category23]: "Розрахунок коригування",
  [tokens.adminPages.integration.vchasno.category.category24]: "Акт коригування",
  [tokens.adminPages.integration.vchasno.category.category25]: "Гарантійний лист",
  [tokens.adminPages.integration.vchasno.category.category26]: "Бухгалтерська довідка",

  [tokens.adminPages.integration.vchasno.table.listEmpty]: "Список порожній",
  [tokens.adminPages.integration.vchasno.table.date]: "Дата",
  [tokens.adminPages.integration.vchasno.table.document]: "Документ",
  [tokens.adminPages.integration.vchasno.table.partner]: "Контрагент",
  [tokens.adminPages.integration.vchasno.table.number]: "Номер",
  [tokens.adminPages.integration.vchasno.table.status]: "Статус",
  [tokens.adminPages.integration.vchasno.table.category]: "Тип",
  [tokens.adminPages.integration.vchasno.table.companyName]: "Компанія",
  [tokens.adminPages.integration.vchasno.table.companyEdrpou]: "ЕДРПОУ",
  [tokens.adminPages.integration.vchasno.table.documentNumber]: "Номер",
  [tokens.adminPages.integration.vchasno.table.amount]: "Сума",
  [tokens.adminPages.integration.vchasno.table.actions]: "Дії",


  [tokens.adminPages.integration.novaposhta.outgoing.title]: "Вихідні посилки",

  [tokens.adminPages.integration.novaposhta.table.listEmpty]: "Список порожній",
  [tokens.adminPages.integration.novaposhta.table.number]: "Номер",
  [tokens.adminPages.integration.novaposhta.table.status]: "Статус",
  [tokens.adminPages.integration.novaposhta.table.createDate]: "Дата створення",
  [tokens.adminPages.integration.novaposhta.table.deliveryDate]: "Планований час доставки",
  [tokens.adminPages.integration.novaposhta.table.sender]: "Відправник",
  [tokens.adminPages.integration.novaposhta.table.senderPhone]: "Контакт відправника",
  [tokens.adminPages.integration.novaposhta.table.recipient]: "Отримувач",
  [tokens.adminPages.integration.novaposhta.table.recipientPhone]: "Контакт отримувача",

  [tokens.adminPages.integration.novaposhta.status.status1]: "Відправник самостійно створив цю накладну, але ще не надав до відправки",
  [tokens.adminPages.integration.novaposhta.status.status2]: "Видалено",
  [tokens.adminPages.integration.novaposhta.status.status3]: "Номер не знайдено",
  [tokens.adminPages.integration.novaposhta.status.status4]: "Відправлення у місті. Очікуйте додаткове повідомлення про прибуття",
  [tokens.adminPages.integration.novaposhta.status.status41]: "Відправлення у місті. Очікуйте додаткове повідомлення про прибуття",
  [tokens.adminPages.integration.novaposhta.status.status5]: "Відправлення прямує до міста",
  [tokens.adminPages.integration.novaposhta.status.status6]: "Відправлення вже місті. Очікуйте додаткове повідомлення про прибуття",
  [tokens.adminPages.integration.novaposhta.status.status7]: "Прибув на відділення",
  [tokens.adminPages.integration.novaposhta.status.status8]: "Прибув на відділення (завантажено в Поштомат)",
  [tokens.adminPages.integration.novaposhta.status.status9]: "Відправлення отримано",
  [tokens.adminPages.integration.novaposhta.status.status10]: "Відправлення отримано. Протягом доби ви одержите SMS-повідомлення про надходження грошового переказу та зможете отримати його в касі відділення «Нова пошта»",
  [tokens.adminPages.integration.novaposhta.status.status11]: "Відправлення отримано. Грошовий переказ видано одержувачу.",
  [tokens.adminPages.integration.novaposhta.status.status12]: "Нова Пошта комплектує ваше відправлення",
  [tokens.adminPages.integration.novaposhta.status.status101]: "На шляху до одержувача",
  [tokens.adminPages.integration.novaposhta.status.status102]: "Відмова від отримання (Відправником створено замовлення на повернення)",
  [tokens.adminPages.integration.novaposhta.status.status103]: "Відмова одержувача (отримувач відмовився від відправлення)",
  [tokens.adminPages.integration.novaposhta.status.status104]: "Змінено адресу",
  [tokens.adminPages.integration.novaposhta.status.status105]: "Припинено зберігання",
  [tokens.adminPages.integration.novaposhta.status.status106]: "Одержано і створено ЄН зворотньої доставки",
  [tokens.adminPages.integration.novaposhta.status.status111]: "Невдала спроба доставки через відсутність Одержувача на адресі або зв'язку з ним",
  [tokens.adminPages.integration.novaposhta.status.status112]: "Дата доставки перенесена Одержувачем",

  [tokens.adminPages.integration.novaposhta.shortStatus.statusOpen]: "Створено",
  [tokens.adminPages.integration.novaposhta.shortStatus.statusInProgress]: "У дорозі",
  [tokens.adminPages.integration.novaposhta.shortStatus.statusError]: "Помилка",
  [tokens.adminPages.integration.novaposhta.shortStatus.statusInDepartment]: "Прибув у відділення",
  [tokens.adminPages.integration.novaposhta.shortStatus.statusDone]: "Отримано",

  [tokens.adminPages.settings.integration.googleDrive]: "Google диск",
  [tokens.adminPages.settings.integration.googleDriveButtonConnect]: "Підключити",
  [tokens.adminPages.settings.integration.googleDriveButtonDeleteAccount]:"Відключити",
  [tokens.adminPages.settings.integration.warningDescription]:
      "Ви впевнені, що хочете видалити акаунт? Після видалення неможливо виконувати дії з файлами",

  [tokens.adminPages.settings.integration.vchasno.title]: "Вчасно",
  [tokens.adminPages.settings.integration.vchasno.lastUpdate]: "Остання синхронізація",
  [tokens.adminPages.settings.integration.vchasno.successCreated]: "Успішно створенно",
  [tokens.adminPages.settings.integration.vchasno.warningDescription]:
      "Ви впевнені, що хочете видалити ключ API для партнера? Після видалення партнер не зможе використовувати сервіс.",
  [tokens.adminPages.settings.integration.vchasno.createVchasnoConnectionFormTitle]: "Додати інтеграцію",
  [tokens.adminPages.settings.integration.vchasno.updateVchasnoConnectionFormTitle]: "Оновити API токен",
  [tokens.adminPages.settings.integration.vchasno.apiKeyTextFieldLabel]: "API ключ",
  [tokens.adminPages.settings.integration.vchasno.intervalSyncCheckboxTitle]: "Синхронізація",
  [tokens.adminPages.settings.integration.vchasno.intervalSyncTitle]: "Період (хв.)",

  [tokens.adminPages.settings.integration.novaposhta.title]: "Нова пошта",
  [tokens.adminPages.settings.integration.novaposhta.name]: "Назва організації",
  [tokens.adminPages.settings.integration.novaposhta.apiToken]: "API Token",
  [tokens.adminPages.settings.integration.novaposhta.successCreated]: "Успішно створенно",

  //Payment
  [tokens.adminPages.settings.payment.automatic.title]: "Автоматична синхронізація оплат",
  [tokens.adminPages.settings.payment.automatic.company]: "Компанія",
  [tokens.adminPages.settings.payment.automatic.lastUpdate]: "Остання синхронізація",
  [tokens.adminPages.settings.payment.automatic.form.title]: "Додати автоматичну синхронізацію оплат",
  [tokens.adminPages.settings.payment.automatic.form.buttonTitle]: "Додати",
  [tokens.adminPages.settings.payment.automatic.form.startDateTitle]: "Початок синхронізації",
  [tokens.adminPages.settings.payment.automatic.form.successAutoEnrollPayment]: "Успішно створенно",
  [tokens.adminPages.settings.payment.automatic.form.companyRequired]: "Потрібно обрати компанію",
  [tokens.adminPages.settings.payment.automatic.form.lastPaymentDateRequired]: "Потрібно вказати дату початку синхронізації",

  //ACCOUNT PAGE
  [tokens.accountPage.title]: "Профіль",
  [tokens.accountPage.pageGeneral]: "Загальний",
  [tokens.accountPage.pageSecurity]: "Безпека",
  //ACCOUNT PAGE: GENERAL PAGE
  [tokens.accountPage.general.title]: "Основні деталі",
  [tokens.accountPage.general.chooseImg]: "Вибрати",
  [tokens.accountPage.general.formId]: "AID",
  [tokens.accountPage.general.formLogin]: "Login",
  [tokens.accountPage.general.formEmail]: "Email",
  [tokens.accountPage.general.phoneNumber]: "Номер телефону",
  [tokens.accountPage.general.deleteAccount]: "Видалити акаунт",
  [tokens.accountPage.general.deleteAccountData]:
    "Видалити свій обліковий запис у Legals і всі вихідні дані.",
  //ACCOUNT PAGE: SECURITY PAGE
  [tokens.accountPage.security.title]: "Історія входу",
  [tokens.accountPage.security.subheader]: "Ваша нещодавня активність входу",
  [tokens.accountPage.security.loginType]: "Тип входу",
  [tokens.accountPage.security.ipAddress]: "IP Адреса",
  [tokens.accountPage.security.entryData]: "Вхід з обліковими даними",
  //COMPANY
  [tokens.company.edrpou]: "ЄДРПОУ",
  [tokens.company.iban]: "Рахунок",
  [tokens.company.mfo]: "МФО",
  [tokens.company.vat]: "Платник ПДВ",
  [tokens.company.headPosition]: "Посада керівника",
  [tokens.company.basis]: "Діє на підставі",
  [tokens.company.legalAddress]: "Юридична  адреса",
  [tokens.company.address]: "Фактична адреса",
  [tokens.company.totalDebt]: "Загальна заборгованість",
  [tokens.company.budgetary]: "Бюджетні",
  [tokens.company.legal]: "Комерційні",
  [tokens.company.users.title]: "Співробітники",
  [tokens.company.users.number]: "№",
  [tokens.company.users.fio]: "ПІБ",
  [tokens.company.users.email]: "Email",
  [tokens.company.users.phone]: "Телефон",
  [tokens.company.users.post]: "Посада",
  [tokens.company.users.status]: "Статус",
  [tokens.company.users.join]: "Додати",
  [tokens.company.users.actions]: "Дії",
  [tokens.company.users.form.title]: "Додати співробітника",
  [tokens.company.users.form.button]: "Додати",
  [tokens.company.users.form.success]: "Співробітника успишно додано",
  [tokens.company.users.search]: "Пошук співробітника",

  [tokens.company.novaposhta.title]: "Нова Пошта",
  [tokens.company.novaposhta.type.all]: "Усі",
  [tokens.company.novaposhta.type.incoming]: "Вхідні",
  [tokens.company.novaposhta.type.outgoing]: "Вихідні",
  [tokens.company.novaposhta.type.archive]: "Архівовані",

  //FORM COMPANY
  [tokens.formCompany.edrpouLength]:
    "Довжина ЄДРПОУ не повинна перевищувати 8 символів",
  [tokens.formCompany.edrpouSpecify]: "Потрібно вказати ЄДРПОУ",
  [tokens.formCompany.edrpouWrong]: "Невірний формат ЄДРПОУ",
  [tokens.formCompany.nameCompany]:
    "Довжина назви не повинна перевищувати 255 символів",
  [tokens.formCompany.nameCompanySpecify]: "Потрібно вказати назву компанії",
  [tokens.formCompany.ibanLength]: "Допустима довжина IBAN 29 символів",
  [tokens.formCompany.ibanSpecify]: "Потрібно вказати IBAN",
  [tokens.formCompany.ibanWrong]: "Неправильний формат IBAN",
  [tokens.formCompany.legalAddressSpecify]: "Потрібно вказати юридичну адресу",
  [tokens.formCompany.factualAddress]: "Потрібно вказати фактичну адресу",
  [tokens.formCompany.mfoSpecify]: "Потрібно вказати МФО",
  [tokens.formCompany.editInformationCompany]:
    "Редагування інформації про компанію",
  [tokens.formCompany.editInformationHeadCompany]:
    "Редагування інформації про керівника",

  [tokens.formCompany.basisSpecify]: "Потрібно вказати підставу",
  [tokens.formCompany.addressMaches]: "Фактична адреса збігается з юридичною",
  [tokens.formCompany.budgetary]: "Компанія є бюджетною",
  [tokens.formCompany.headNameSpecify]: "Потрібно вказати керівника",
  [tokens.formCompany.headPositionSpecify]: "Потрібно вказати посаду керівника",
  [tokens.formCompany.mfoSpecifyMaxLength]:
    "Довжина МФО не повинна перевищувати 6 символів",
  [tokens.formCompany.taxLength]: `Допустима довжина ІПН 10 символів`,
  [tokens.formCompany.taxSpecify]: `Потрібно вказати ІПН`,

  //DOCUMENT
  [tokens.document.typeDocument]: "Тип документа",
  [tokens.document.nameDocument]: "Назва документа чи номер",
  [tokens.document.internetContract]: "Договір на надання послуг Інтернету",
  [tokens.document.connectionContract]: "Договір на підключення",
  [tokens.document.provisionOfServicesContract]: "Договір на надання послуг",
  [tokens.document.connectionBill]: "Рахунок на підключення",
  [tokens.document.documentFormat]: "Формат документа",
  [tokens.document.googleDrive]: "Зберегти на Google Drive",
  [tokens.document.generateDocument]: "Згенерувати документ",
  [tokens.document.errorParentDocument]: "Необхідно обрати первинний документ",
  [tokens.document.templateManualOptionsEmpty]: "Шаблон не має ручних параметрів",
  [tokens.document.downloadDocument]: "Завантажити документ",
  [tokens.document.numberDocument]: "Номер документа",
  [tokens.document.maximumFile]: "Максимальний розмір файлу 20 МБ",
  [tokens.document.pdf]: "PDF",
  [tokens.document.doc]: "DOC",
  [tokens.document.docx]: "DOCX",
  [tokens.document.companySearch]: "Введіть ЄДРПОУ aбо назву компанії",
  [tokens.document.selectCompanyError]: "Спочатку виберіть компанію!",
  [tokens.document.companyNotFound]: "Компанію не знайдено",
  [tokens.document.startAction]: "Початок дії",
  [tokens.document.numberDoc]: "Введіть номер первинного документа",
  [tokens.document.selectDoc]: "Спочатку виберіть документ!",
  [tokens.document.documentNotFount]: "Документи не знайдено",
  [tokens.document.listEmpty]: "Перелік документів порожній",
  [tokens.document.searchDoc]: "Пошук документа",
  [tokens.document.dateIssue]: " Date of issue",
  [tokens.document.numberInvoice]: "Number",
  [tokens.document.billed]: " Billed to",
  [tokens.document.grid]: "#",
  [tokens.document.description]: "Description",
  [tokens.document.qty]: "Qty",
  [tokens.document.unitPrice]: "Unit Price",
  [tokens.document.total]: "Total",
  [tokens.document.subtotal]: "Subtotal",
  [tokens.document.taxes]: "Taxes",
  [tokens.document.notes]: "Notes",
  [tokens.document.listsEmpty]: 'Список шаблонів порожній!',

  //analytics
  [tokens.analytics.index]: "Analytics",
  [tokens.analytics.title]: "Аналітика",
  [tokens.analytics.totalDebt]: "Загальна заборгованість",
  [tokens.analytics.table.number]: "№",
  [tokens.analytics.table.company]: "Компанія",
  [tokens.analytics.table.edrpou]: "ЄДРПОУ",
  [tokens.analytics.table.lastPayment]: "Остання оплата",
  [tokens.analytics.table.countOfLoginPI]: "Загальна кількість логінів",
  [tokens.analytics.table.totalDebtSum]: "Загальна сума боргу/переплати",
  [tokens.analytics.table.countOfMonthDebt]: "Кількість місяців боргу/переплати",

  //DASHBOARD
  [tokens.dashboard.dashboard]: "Dashboard",
  [tokens.dashboard.vehiclesDeviatedRoute]: "Vehicles deviated from route",
  [tokens.dashboard.vehiclesErrors]: "Vehicles with errors",
  [tokens.dashboard.fleet]: "Fleet",
  [tokens.dashboard.mapCannotLoaded]: " Map cannot be loaded",
  [tokens.dashboard.mapBoxApiKey]: "Mapbox API Key is not configured.",
  [tokens.dashboard.temperature]: "Temperature",
  [tokens.dashboard.temperatureGood]: "good",
  [tokens.dashboard.trackingNumberCreated]: "Tracking Number Created",
  [tokens.dashboard.outForDelivery]: "Out for Delivery",
  [tokens.dashboard.arrived]: "Arrived",
  [tokens.dashboard.totalCompanies]: "Усього компаній",
  [tokens.dashboard.veryGood]: "Very good",
  [tokens.dashboard.excellent]: "Excellent",
  [tokens.dashboard.good]: "Good",
  [tokens.dashboard.goodCondition]: "Good condition",
  [tokens.dashboard.bad]: "Bad",
  [tokens.dashboard.needsAttention]: "Needs attention",
  [tokens.dashboard.vehiclesCondition]: "Vehicles Condition",
  [tokens.dashboard.routeVehicles]: "On Route Vehicles",
  [tokens.dashboard.conditionTemperature]: "Condition and temperature",
  [tokens.dashboard.location]: "Location",
  [tokens.dashboard.endingRoute]: "Ending Route",
  [tokens.dashboard.startingRoute]: "Starting Route",
  [tokens.dashboard.warnings]: "Warnings",
  [tokens.dashboard.noWarnings]: "No warnings",
  [tokens.dashboard.refrigeratorTemperature]: "Refrigerator Temperature",
  [tokens.dashboard.vehiclesOverview]: "Vehicles Overview",
  [tokens.dashboard.total]: "Total",
  [tokens.dashboard.panel]: "Панель",
  [tokens.dashboard.available]: "Available",
  [tokens.dashboard.outService]: "Out of service",
  [tokens.dashboard.onRoute]: "On route",
  [tokens.dashboard.temperatureNotOptimal]: "Temperature not optimal",
  [tokens.dashboard.notResponding]: "ECU not responding",
  [tokens.dashboard.companiesCountWidgetTitle]: "Компанії",
  [tokens.dashboard.userCountWidgetTitle]: "Користувачі",
  [tokens.dashboard.loginPICountWidgetTitle]: "Точки підключення",
  [tokens.dashboard.loginPICountNotActiveWidgetTitle]:
    "Не активні точки підключення",
  [tokens.error.errorFind]: "Error find form",
  [tokens.error.errorSave]: "Error save document",
  [tokens.error.download]: "Error download document",
  [tokens.error.errorUser]: "Ошибка авторизации, попробуйте позже",
};
