import api from "../api";

export interface MistakeMessage {
    username: string,
    message?: string,
    file?: File
}

class HelpApi {
    sendMistakeMessage(message: MistakeMessage): Promise<any> {
        const formData = new FormData();
        if (message.file) {
            formData.append("file", message.file);
        }
        const request = {
            username: message.username,
            message: message.message,
        }
        const json = JSON.stringify(request);
        const blob = new Blob([json], {
            type: 'application/json'
        });
        formData.append("mistake", blob);
        return api.post<string>("/public/help/send-mistake", formData)
    }
}

export const helpApi = new HelpApi();