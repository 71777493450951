import {useEffect} from 'react';

export const usePageView = (): void => {
  useEffect(
    () => {
       // intentionally empty
    },
    []
  );
};
