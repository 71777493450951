import type {User} from 'src/types/user';
import {Role} from "../utils/role";

export const useMockedUser = (): User => {
  // To get the user from the authContext, you can use
  // `const { user } = useAuth();`
  return {
    userId: 1,
    avatar: '/assets/avatars/avatar-carson-darrin.png',
    email: 'user1@gmail.com',
    firstName: '',
    lastName: '',
    username: 'user1@gmail.com',
    fullName: 'Комаров Дмитрий',
    phone: '0939329290',
    telegram: true,
    viber: false,
    disabled: true,
    temporaryPassword: false,
    countCompanies: 0,
    roles: [Role.ADMIN]
  };
};
