import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Company, CompanyContact, CompanyEditRequest} from "../../../types/company";
import {Loginpi} from "../../../types/loginpi";
import {Document} from "../../../types/document";
import {CompanyUser} from "../../../types/user";
import {NovaPoshtaDocument, NovaPoshtaDocumentType} from "../../../types/novaposhta";

interface CompanyItemState {
    loadingCompanyItem: boolean;
    loadingCompanyItemDetail: boolean;
    loadingCompanyItemContacts: boolean;
    loadingCompanyItemUsers: boolean;
    loadingCompanyItemNovaPoshtaDocuments: boolean;
    companyItem: Company;
    companyItemContacts: CompanyContact[],
    companyItemDocumentPage: {
        loadingCompanyItemDocuments: boolean,
        documentsPage: {
            data: Document[],
            currentPage: number,
            totalPages: number,
            totalElements: number
        },
        documentsSearch: {
            query: string,
            page: number,
            rowsPerPage: number,
            sortBy: string,
        }
    },
    companyItemLoginPIPage: {
        isLoadingCompanyItemLoginPIPage: boolean,
        companyItemLoginPIPage: {
            data: Loginpi[],
            currentPage: number,
            totalPages: number,
            totalElements: number
        },
        companyItemLoginPISearch: {
            query: string,
            page: number,
            rowsPerPage: number,
            sortBy: string,
        }
    },
    companyItemUsersPage: {
        data: CompanyUser[],
        currentPage: number,
        totalPages: number,
        totalElements: number
    },
    companyItemUsersSearch: {
        query: string,
        page: number,
        rowsPerPage: number,
        sortBy: string,
    },
    companyItemNovaPoshtaPage: {
        data: NovaPoshtaDocument[],
        currentPage: number,
        totalPages: number,
        totalElements: number
    },
    companyItemNovaPoshtaSearch: {
        query: string,
        companyId: number;
        type?: NovaPoshtaDocumentType;
        page: number,
        rowsPerPage: number,
        sortBy: string,
    }
}

const initialState: CompanyItemState = {
    loadingCompanyItem: false,
    loadingCompanyItemDetail: false,
    loadingCompanyItemContacts: false,
    loadingCompanyItemUsers: false,
    loadingCompanyItemNovaPoshtaDocuments: false,
    companyItem: {
        id: 0,
        userId: 0,
        budget: false,
        name: '',
        edrpou: '',
        iban: '',
        mfo: '',
        taxId: '',
        basis: '',
        headPosition: '',
        headName: '',
        created: '',
        legalAddress: '',
        legalAddressEqualsAddress: false,
        address: '',
        confirmed: false,
    },
    companyItemContacts: [],
    companyItemDocumentPage: {
        loadingCompanyItemDocuments: false,
        documentsPage: {
            data: [],
            currentPage: 0,
            totalPages: 0,
            totalElements: 0
        },
        documentsSearch: {
            query: '',
            page: 0,
            rowsPerPage: 10,
            sortBy: 'id',
        }
    },
    companyItemLoginPIPage: {
        isLoadingCompanyItemLoginPIPage: false,
        companyItemLoginPIPage: {
            data: [],
            currentPage: 0,
            totalPages: 0,
            totalElements: 0
        },
        companyItemLoginPISearch: {
            query: '',
            page: 0,
            rowsPerPage: 10,
            sortBy: 'uid',
        }
    },
    companyItemUsersPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    companyItemUsersSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id',
    },
    companyItemNovaPoshtaPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    companyItemNovaPoshtaSearch: {
        query: '',
        companyId: 0,
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id',
    },
};

interface LoginPIPageSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface UsersPageSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface NovaPoshtaDocumentsSearch {
    query: string;
    companyId: number;
    type?: NovaPoshtaDocumentType;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface DocumentPageSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface LoginPIPage {
    data: Loginpi[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface UsersPage {
    data: CompanyUser[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface NovaPoshtaDocumentsPage {
    data: NovaPoshtaDocument[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface DocumentPage {
    data: Document[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type LoadingCompanyItemAction = PayloadAction<boolean>;
type LoadingCompanyItemDetailAction = PayloadAction<boolean>;
type LoadingCompanyItemContactsAction = PayloadAction<boolean>;
type LoadingCompanyItemDocumentsAction = PayloadAction<boolean>;
type LoadingCompanyItemUsersAction = PayloadAction<boolean>;
type CompanyItemAction = PayloadAction<Company>;
type CompanyItemUpdateAction = PayloadAction<CompanyEditRequest>;
type CompanyItemContactsAction = PayloadAction<CompanyContact[]>;
type CompanyItemContactAction = PayloadAction<CompanyContact>;
type CompanyItemContactDeleteAction = PayloadAction<number>;
type LoadingCompanyItemLoginPIPageAction = PayloadAction<boolean>;
type FilterCompanyItemLoginPIPageAction = PayloadAction<LoginPIPageSearch>;
type FilterCompanyItemUsersPageAction = PayloadAction<UsersPageSearch>;
type FilterCompanyItemNovaPoshtaAction = PayloadAction<NovaPoshtaDocumentsSearch>;
type LoadingCompanyItemNovaPoshtaAction = PayloadAction<boolean>;
type FilterCompanyItemDocumentPageAction = PayloadAction<DocumentPageSearch>;
type GetCompanyItemLoginPIPageAction = PayloadAction<LoginPIPage>;
type GetCompanyItemUsersPageAction = PayloadAction<UsersPage>;
type GetCompanyItemNovaPoshtaPageAction = PayloadAction<NovaPoshtaDocumentsPage>;
type UpdateCompanyItemUserAction = PayloadAction<CompanyUser>;
type DeleteJoinCompanyItemUserAction = PayloadAction<number>;
type GetCompanyItemDocumentPageAction = PayloadAction<DocumentPage>;


const reducers = {
    onLoadingCompanyItemChange(state: CompanyItemState, action: LoadingCompanyItemAction): void {
        state.loadingCompanyItem = action.payload;
    },
    onLoadingCompanyItemDetailChange(state: CompanyItemState, action: LoadingCompanyItemDetailAction): void {
        state.loadingCompanyItem = action.payload;
    },
    onLoadingCompanyItemContactsChange(state: CompanyItemState, action: LoadingCompanyItemContactsAction): void {
        state.loadingCompanyItemContacts = action.payload;
    },
    onLoadingCompanyItemUsersChange(state: CompanyItemState, action: LoadingCompanyItemUsersAction): void {
        state.loadingCompanyItemUsers = action.payload;
    },
    onCompanyItem(state: CompanyItemState, action: CompanyItemAction): void {
        state.companyItem = action.payload;
    },
    onCompanyItemUpdate(state: CompanyItemState, action: CompanyItemUpdateAction): void {
        state.companyItem = {
            ...state.companyItem,
            iban: action.payload.iban,
            budget: !!action.payload.budget,
            name: action.payload.name,
            mfo: action.payload.mfo,
            taxId: action.payload.taxId ? action.payload.taxId : null,
            basis: action.payload.basis,
            headPosition: action.payload.headPosition,
            headName: action.payload.headName,
            legalAddress: action.payload.legalAddress,
            legalAddressEqualsAddress: !!action.payload.legalAddressEqualsAddress,
            address: action.payload.address,
        };
    },
    onCompanyItemContacts(state: CompanyItemState, action: CompanyItemContactsAction): void {
        state.companyItemContacts = action.payload;
    },
    onCompanyItemContact(state: CompanyItemState, action: CompanyItemContactAction): void {
        const contacts = [...state.companyItemContacts];
        contacts.push(action.payload);
        state.companyItemContacts = contacts.sort((a, b) => {
            if (a.type.id < b.type.id) {
                return 1;
            }
            if (a.type.id > b.type.id) {
                return -1;
            }
            return 0;
        });
    },
    onDeleteCompanyItemContact(state: CompanyItemState, action: CompanyItemContactDeleteAction): void {
        const contacts = [...state.companyItemContacts];
        const index = contacts.findIndex((contact) => contact.id === action.payload)
        if (index > -1) {
            contacts.splice(index, 1);
        }
        state.companyItemContacts = contacts;
    },
    onLoadingCompanyItemDocumentPageChange(state: CompanyItemState, action: LoadingCompanyItemDocumentsAction): void {
        state.companyItemDocumentPage.loadingCompanyItemDocuments = action.payload;
    },
    onFilterCompanyItemDocumentPageChange(state: CompanyItemState, action: FilterCompanyItemDocumentPageAction): void {
        state.companyItemDocumentPage.documentsSearch = action.payload;
    },
    onGetCompanyItemDocumentPage(state: CompanyItemState, action: GetCompanyItemDocumentPageAction): void {
        state.companyItemDocumentPage.documentsPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onLoadingCompanyItemLoginPIPageChange(state: CompanyItemState, action: LoadingCompanyItemLoginPIPageAction): void {
        state.companyItemLoginPIPage.isLoadingCompanyItemLoginPIPage = action.payload;
    },
    onFilterCompanyItemLoginPIPageChange(state: CompanyItemState, action: FilterCompanyItemLoginPIPageAction): void {
        state.companyItemLoginPIPage.companyItemLoginPISearch = action.payload;
    },
    onGetCompanyItemLoginPIPage(state: CompanyItemState, action: GetCompanyItemLoginPIPageAction): void {
        state.companyItemLoginPIPage.companyItemLoginPIPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onFilterCompanyItemUsersPageChange(state: CompanyItemState, action: FilterCompanyItemUsersPageAction): void {
        state.companyItemUsersSearch = action.payload;
    },
    onGetCompanyItemUsersPage(state: CompanyItemState, action: GetCompanyItemUsersPageAction): void {
        state.companyItemUsersPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
    onUpdateCompanyItemUsersPage(state: CompanyItemState, action: UpdateCompanyItemUserAction): void {
        let companyItemUserPageData = [...state.companyItemUsersPage.data];
        companyItemUserPageData = companyItemUserPageData.map((userCompany) => {
            if (userCompany.userId === action.payload.userId) {
                return action.payload;
            }
            return userCompany;
        })
        state.companyItemUsersPage.data = companyItemUserPageData;
    },
    onDeleteJoinCompanyItemUserPage(state: CompanyItemState, action: DeleteJoinCompanyItemUserAction): void {
        const users = [...state.companyItemUsersPage.data];
        const index = users.findIndex((user) => user.userId === action.payload)
        if (index > -1) {
            users.splice(index, 1);
        }
        state.companyItemUsersPage.data = users;
    },
    onFilterCompanyItemNovaPoshtaChange(state: CompanyItemState, action: FilterCompanyItemNovaPoshtaAction): void {
        state.companyItemNovaPoshtaSearch = action.payload;
    },
    onLoadingCompanyItemNovaPoshtaChange(state: CompanyItemState, action: LoadingCompanyItemNovaPoshtaAction): void {
        state.loadingCompanyItemNovaPoshtaDocuments = action.payload;
    },
    onGetCompanyItemNovaPoshtaPage(state: CompanyItemState, action: GetCompanyItemNovaPoshtaPageAction): void {
        state.companyItemNovaPoshtaPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
}


export const slice = createSlice({
    name: 'companyItem',
    initialState,
    reducers
});

export const {reducer} = slice;