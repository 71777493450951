import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Loginpi} from "../../../types/loginpi";

interface LoginsPiPage {
    data: Loginpi[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface LoginsPiSearch {
    query: string;
    page: number;
    rowsPerPage: number;
    sortBy: string;
}

interface LoginsPiState {
    isLoadingLoginsPi: boolean;
    loginspiPage: LoginsPiPage;
    loginspiSearch: LoginsPiSearch;
}

interface LoginsPiAction {
    data: Loginpi[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetLoginsPiAction = PayloadAction<LoginsPiAction>;

type LoadingLoginPiAction = PayloadAction<boolean>;

type FilterAction = PayloadAction<LoginsPiSearch>;

const initialState: LoginsPiState = {
    isLoadingLoginsPi: false,
    loginspiPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    loginspiSearch: {
        query: '',
        page: 0,
        rowsPerPage: 10,
        sortBy: 'uid',
    }
};


const reducers = {
    onLoadingLoginPiChange(state: LoginsPiState, action: LoadingLoginPiAction): void {
        state.isLoadingLoginsPi = action.payload;
    },

    onFilterChange(state: LoginsPiState, action: FilterAction): void {
        state.loginspiSearch = action.payload;
    },

    onGetLoginsPi(state: LoginsPiState, action: GetLoginsPiAction): void {
        state.loginspiPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    }
}

export const slice = createSlice({
    name: 'loginspi',
    initialState,
    reducers
});

export const {reducer} = slice;