import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {VchasnoInfo} from "../../../../../types/vchasno";
import {SyncStatus} from "../../../../../types/sync-status";

interface VchasnoConnectionsState {
    isVchasnoConnectionsLoading: boolean,
    vchasnoConnections: VchasnoInfo[];
}

const initialState: VchasnoConnectionsState = {
    isVchasnoConnectionsLoading: false,
    vchasnoConnections: []
};

type LoadingLoadingVchasnoConnections = PayloadAction<boolean>;
type GetVchasnoConnectionsAction = PayloadAction<VchasnoInfo[]>;
type ChangeStatusVchasnoConnectionAction = PayloadAction<{
    id: number;
    syncStatus: SyncStatus;
}>;
type DeleteVchasnoConnectionAction = PayloadAction<number>;
type CreateVchasnoConnectionAction = PayloadAction<VchasnoInfo>;
type UpdateVchasnoConnectionAction = PayloadAction<{
    connectionId: number,
    apiKey: string,
    intervalSyncMin?: number
}>;

const reducers = {
    onLoadingVchasnoConnections(state: VchasnoConnectionsState, action: LoadingLoadingVchasnoConnections): void {
        state.isVchasnoConnectionsLoading = action.payload;
    },
    onGetVchasnoConnections(state: VchasnoConnectionsState, action: GetVchasnoConnectionsAction): void {
        state.vchasnoConnections = action.payload;
    },
    onChangeStatusVchasnoConnection(state: VchasnoConnectionsState, action: ChangeStatusVchasnoConnectionAction): void {
        let vchasnoConnections = [...state.vchasnoConnections];
        vchasnoConnections = vchasnoConnections.map((connection) => {
            if (connection.id === action.payload.id) {
                connection.syncStatus = action.payload.syncStatus;
            }
            return connection;
        })
        state.vchasnoConnections = vchasnoConnections;
    },
    onCreateVchasnoConnection(state: VchasnoConnectionsState, action: CreateVchasnoConnectionAction): void {
        const vchasnoConnections = [...state.vchasnoConnections];
        vchasnoConnections.push(action.payload);
        state.vchasnoConnections = vchasnoConnections;
    },
    onDeleteVchasnoConnection(state: VchasnoConnectionsState, action: DeleteVchasnoConnectionAction): void {
        const vchasnoConnections = [...state.vchasnoConnections];
        const index = vchasnoConnections.findIndex((vchasnoConnection) => vchasnoConnection.id === action.payload)
        if (index > -1) {
            vchasnoConnections.splice(index, 1);
        }
        state.vchasnoConnections = vchasnoConnections;
    },
    onUpdateVchasnoConnection(state: VchasnoConnectionsState, action: UpdateVchasnoConnectionAction): void {
        let vchasnoConnections = [...state.vchasnoConnections];
        vchasnoConnections = vchasnoConnections.map((vchasnoConnection) => {
            if (vchasnoConnection.id === action.payload.connectionId) {
                vchasnoConnection.apiKey = action.payload.apiKey;
                if (action.payload.intervalSyncMin) {
                    vchasnoConnection.intervalSyncMin = action.payload.intervalSyncMin;
                }
            }
            return vchasnoConnection;
        })
        state.vchasnoConnections = vchasnoConnections;
    },
}

export const slice = createSlice({
    name: 'vchasnoConnections',
    initialState,
    reducers
});

export const {reducer} = slice;