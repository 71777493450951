import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {VchasnoDocument, VchasnoDocumentsFilter, VchasnoDocumentType} from "../../../../types/vchasno";

interface VchasnoDocumentsState {
    isVchasnoDocumentsLoading: boolean;
    vchasnoDocumentsPage: VchasnoDocumentPage;
    vchasnoDocumentsFilter: VchasnoDocumentsFilter;
}

const initialState: VchasnoDocumentsState = {
    isVchasnoDocumentsLoading: false,
    vchasnoDocumentsPage: {
        data: [],
        currentPage: 0,
        totalPages: 0,
        totalElements: 0
    },
    vchasnoDocumentsFilter: {
        query: '',
        type: VchasnoDocumentType.SOURCE_DOCUMENT,
        partnerEdrpou: '',
        page: 0,
        rowsPerPage: 10,
        sortBy: 'id'
    }
};

interface VchasnoDocumentPage {
    data: VchasnoDocument[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

interface VchasnoDocumentsAction {
    data: VchasnoDocument[];
    currentPage: number;
    totalPages: number;
    totalElements: number;
}

type GetPageOfVchasnoDocumentsAction = PayloadAction<VchasnoDocumentsAction>;

type VchsnoDocumentsLoadingAction = PayloadAction<boolean>;

type VchsnoDocumentsFilterAction = PayloadAction<VchasnoDocumentsFilter>;

const reducers = {
    onVchasnoDocumentsLoadingChange(state: VchasnoDocumentsState, action: VchsnoDocumentsLoadingAction): void {
        state.isVchasnoDocumentsLoading = action.payload;
    },

    onVchasnoDocumentsFilterChange(state: VchasnoDocumentsState, action: VchsnoDocumentsFilterAction): void {
        state.vchasnoDocumentsFilter = action.payload;
    },

    onPageOfVchasnoDocuments(state: VchasnoDocumentsState, action: GetPageOfVchasnoDocumentsAction): void {
        state.vchasnoDocumentsPage = {
            data: action.payload.data,
            currentPage: action.payload.currentPage,
            totalPages: action.payload.totalPages,
            totalElements: action.payload.totalElements
        }
    },
}


export const slice = createSlice({
    name: 'vchasnoDocumentsList',
    initialState,
    reducers
});

export const {reducer} = slice;