import {User} from "../../../types/user";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface UserItemState {
    loadingUserItem: boolean;
    updatingUserItem: boolean;
    user: User;
}

const initialState: UserItemState = {
    loadingUserItem: false,
    updatingUserItem: false,
    user: {
        userId: 0,
        username: "",
        email: "",
        firstName: "",
        lastName: "",
        fullName: "",
        phone: "",
        disabled: true,
        temporaryPassword: false,
        countCompanies: 0,
        roles: []
    }
};

interface UpdateUserItem {
    avatar?: string;
    firstName: string;
    lastName?: string;
    phone: string;
    telegram?: boolean;
    viber?: boolean;
    disabled: boolean;
}

type LoadingUserItemAction = PayloadAction<boolean>;
type UpdatingUserItemAction = PayloadAction<boolean>;
type UserItemAction = PayloadAction<User>;
type UpdateUserItemAction = PayloadAction<UpdateUserItem>;
type UserItemStatusAction = PayloadAction<boolean>;

const reducers = {
    onLoadingUserItemChange(state: UserItemState, action: LoadingUserItemAction): void {
        state.loadingUserItem = action.payload;
    },
    onUpdatingUserItemChange(state: UserItemState, action: UpdatingUserItemAction): void {
        state.updatingUserItem = action.payload;
    },
    onUserItem(state: UserItemState, action: UserItemAction) : void {
        state.user = action.payload;
    },
    onUpdateUserItem(state: UserItemState, action: UpdateUserItemAction) : void {
        const fullName = `${action.payload.firstName} ${action.payload.lastName}`;
        state.user = {
            ...state.user,
            avatar: action.payload.avatar,
            fullName: fullName,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            phone: action.payload.phone,
            telegram: action.payload.telegram,
            viber: action.payload.viber,
            disabled: action.payload.disabled,
        }
    },
    onUserItemStatusChange(state: UserItemState, action: UserItemStatusAction): void {
        state.user = {
            ...state.user,
            disabled: action.payload
        }
    }
}

export const slice = createSlice({
    name: 'userItem',
    initialState,
    reducers
});

export const {reducer} = slice;