import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Payment, PaymentStatus, PaymentTransactionDetail} from "../../../types/payment";

interface PaymentsItemState {
    isPaymentsItemLoading: boolean;
    isPaymentsItemTransactionsLoading: boolean;
    payment: Payment;
    paymentTransactions: PaymentTransactionDetail[];
}

const initialState: PaymentsItemState = {
    isPaymentsItemLoading: false,
    isPaymentsItemTransactionsLoading: false,
    payment: {
        statementId: 0,
        technicalTransactionId: '',
        partner: {
            id: 0,
            name: '',
            shortName: '',
            edrpou: '',
        },
        sum: 0,
        osnd: '',
        time: '',
        status: PaymentStatus.REGISTERED
    },
    paymentTransactions: []
};

type PaymentsItemLoadingAction = PayloadAction<boolean>;
type PaymentsItemTransactionsLoadingAction = PayloadAction<boolean>;
type GetPaymentsItemAction = PayloadAction<Payment>;
type GetPaymentsItemTransactionsAction = PayloadAction<PaymentTransactionDetail[]>;

const reducers = {
    onPaymentsItemLoadingChange(state: PaymentsItemState, action: PaymentsItemLoadingAction): void {
        state.isPaymentsItemLoading = action.payload;
    },

    onPaymentsItemTransactionsLoadingChange(state: PaymentsItemState, action: PaymentsItemTransactionsLoadingAction): void {
        state.isPaymentsItemTransactionsLoading = action.payload;
    },

    onPaymentsItemChange(state: PaymentsItemState, action: GetPaymentsItemAction): void {
        state.payment = action.payload
    },

    onPaymentsItemTransactionsChange(state: PaymentsItemState, action: GetPaymentsItemTransactionsAction): void {
        state.paymentTransactions = action.payload
    },
}


export const slice = createSlice({
    name: 'paymentsItem',
    initialState,
    reducers
});

export const {reducer} = slice;