export const paths = {
  index: '/',
  auth: {
    jwt: {
      login: '/login',
      register: '/register'
    },
  },
  admin: {
    index: '/admin',
    analytics: '/admin/analytics',
    account: '/admin/account',
    companies: {
      index: '/admin/companies',
      details: '/admin/companies/:companiesId',
      edit: '/admin/companies/:companiesId/edit'
    },
    users: {
      index: '/admin/users',
      details: '/admin/users/:usersId',
      edit: '/admin/users/:usersId/edit',
    },
    logins: {
      index: '/admin/logins',
      details: '/admin/logins/:loginPI',
    },
    payments: {
      index: '/admin/payments',
      detail: '/admin/payments/:technicalTransactionId/transactions',
    },
    documents: {
      index: '/admin/documents',
      details: '/dashboard/documents/:documentId',
      view: '/admin/documents/:documentId/view',
    },
    sentDocuments: {
      index: '/admin/sent-documents',
    },
    integration: {
      vchasnoDocuments: '/admin/integration/vchasno/documents',
      vchasnoIncomingDocument: '/admin/integration/vchasno/incoming-documents',
      novaPoshtaOutgoingDocuments: "/admin/integration/novaposhta/outgoing-documents",
      novaPoshtaIncomingDocuments: "/admin/integration/novaposhta/incoming-documents",
      novaPoshtaArchiveDocuments: "/admin/integration/novaposhta/archive-documents",
    },
    employees: {
      index: '/admin/employees',
    },
    settings: {
      templates: '/admin/settings/templates',
      integration: {
        vchasno: '/admin/settings/integration/vchasno',
        novaposhta: '/admin/settings/integration/novaposhta',
        others: '/admin/settings/integration/others'
      },
      payment: {
          method: '/admin/settings/payment/methods',
          automatic: '/admin/settings/payment/automatic',
      },
      directory: '/admin/settings/directory',
    }
  },
  user: {
    index: '/user',
  },
  dashboard: {
    index: '/dashboard',
    academy: {
      index: '/dashboard/academy',
      courseDetails: '/dashboard/academy/courses/:courseId'
    },
    account: '/dashboard/account',
    analytics: '/dashboard/analytics',
    blank: '/dashboard/blank',
    blog: {
      index: '/dashboard/blog',
      postDetails: '/dashboard/blog/:postId',
      postCreate: '/dashboard/blog/create'
    },
    calendar: '/dashboard/calendar',
    chat: '/dashboard/chat',
    crypto: '/dashboard/crypto',
    customers: {
      index: '/dashboard/customers',
      details: '/dashboard/customers/:customerId',
      edit: '/dashboard/customers/:customerId/edit'
    },
    ecommerce: '/dashboard/ecommerce',
    fileManager: '/dashboard/file-manager',
    invoices: {
      index: '/dashboard/invoices',
      details: '/dashboard/invoices/:orderId'
    },
    jobs: {
      index: '/dashboard/jobs',
      create: '/dashboard/jobs/create',
      companies: {
        details: '/dashboard/jobs/companies/:companyId'
      }
    },
    kanban: '/dashboard/kanban',
    logistics: {
      index: '/dashboard/logistics',
      fleet: '/dashboard/logistics/fleet'
    },
    mail: '/dashboard/mail',
    orders: {
      index: '/dashboard/orders',
      details: '/dashboard/orders/:orderId'
    },
    products: {
      index: '/dashboard/products',
      create: '/dashboard/products/create'
    },
    social: {
      index: '/dashboard/social',
      profile: '/dashboard/social/profile',
      feed: '/dashboard/social/feed'
    }
  },
  401: '/401',
  404: '/404',
  500: '/500'
};
