import {Partner} from "./partner";
import {CompanyItem} from "./company";
import {LoginPIItem} from "./loginpi";
import {AdminItem} from "./admin";

export interface Payment {
    statementId: number;
    technicalTransactionId: string;
    company?: CompanyItem;
    partner?: Partner
    sum: number,
    osnd: string;
    time: string;
    status: PaymentStatus;
}

export interface EnrollPayments {
    statementId: number;
    technicalTransactionId: string;
    transactions: PaymentTransaction[]
}

export interface PaymentTransaction {
    id: number;
    uid: number;
    amount: number;
    status: EnrollPaymentStatus;
}

export interface PaymentTransactionDetail {
    id: number;
    technicalTransactionId: string;
    loginPI: LoginPIItem;
    company: CompanyItem;
    admin: AdminItem;
    amount: number;
    status: EnrollPaymentStatus;
    created: string;
    updated: string;
}

export interface PaymentMethod {
    id: number;
    name: string;
    enabled: boolean;
}

export enum PaymentStatus {
    RECEIVED = 1,
    REGISTERED = 2,
    APPROVED = 3,
}

export enum EnrollPaymentStatus {
    PROGRESS = 1,
    FAILED = 2,
    ENROLLED = 3,
}

export interface PaymentFilter {
    query?: string;
    partnerEdrpou?: string;
    startDate?: string;
    endDate?: string;
    page?: number;
    rowsPerPage?: number;
    sortBy?: string;
}