export interface NovaPoshtaConnection {
    id: number;
    name: string;
    apiToken: string;
}

export interface NovaPoshtaConnectionRequest {
    name: string;
    apiToken: string;
}

export enum NovaPoshtaDocumentType {
    SOURCE_DOCUMENT = 1,
    INCOMING_DOCUMENT,
    ARCHIVE_DOCUMENT,
}

export interface NovaPoshtaDocumentFilter {
    query?: string;
    type: NovaPoshtaDocumentType;
    page?: number;
    rowsPerPage?: number;
    sortBy?: string;
}

export interface SettlmentAddress {
    senderWarehouseRef: string;
    recipientWarehouseRef: string;
    senderSettlementRef: string;
    recipientSettlementRef: string;
    senderSettlementDescription: string;
    recipientSettlementDescription: string;
    senderWarehouseNumber: string;
    recipientWarehouseNumber: string;
}

export interface NovaPoshtaInternetDocument {
    MoneyTransferPayerType: string | null;
    MoneyTransferNumber: string;
    ClientBarcodes: string[];
    LightReturnNumber: string;
    DateTime: string;
    CounterpartyRecipientDescription: string;
    MoneyTransferCreationDate: string;
    DocumentWeight: number;
    AfterpaymentOnGoodsCost: string;
    ServiceType: string;
    ArrivalDateTime: string;
    CityRecipient: string; // UUID format as string
    LoyaltyCardRecipient: string | null;
    LoyaltyCardSender: string | null;
    deletionMark: boolean;
    RecipientFullName: string;
    VolumetricWeight: string;
    FactualWeight: string;
    CitySender: string; // UUID format as string
    MarketplacePartnerToken: string;
    CounterpartySenderDescription: string;
    InternationalDeliveryType: string;
    MoneyTransferCommission: number;
    CargoDescription: string;
    SumBeforeCheckWeight: number;
    EndDeliveryTime: string;
    CheckWeight: number;
    Note: string;
    RecipientAddress: string; // UUID format as string
    PaymentMethod: string;
    CitySenderDescription: string;
    CargoType: string;
    SenderArchive: boolean;
    AttorneyDescription: string | null;
    PayerType: string;
    SenderAddress: string; // UUID format as string
    SenderName: string;
    LinkedEWs: string[];
    SeatsAmount: string;
    TrackingStatusCode: string;
    PhoneRecipient: string;
    RecipientAddressDescription: string;
    AdjustedDate: string;
    CityRecipientDescription: string;
    ScheduledDeliveryDate: string;
    Cost: number;
    MoneyTransferCash2Card: string;
    MoneyTransferAmount: string | null;
    Number: string;
    RecipientArchive: boolean;
    MoneyTransferPayerCommission: string;
    PhoneSender: string;
    RecipientName: string;
    DocumentCost: number;
    ScanSheetInternetNumber: string;
    TrackingUpdateDate: string;
    BeginDeliveryTime: string;
    CounterpartyRecipient: string; // UUID format as string
    CargoAutoReturnDate: string;
    FirstDayStorage: string;
    CardMaskedNumber: string;
    CounterpartySender: string; // UUID format as string
    ReceivingDateTime: string;
    OrderingAdditionalServices: string[];
    OrderedBackwardDeliveryDocuments: string[];
    MoneyTransferTransactionDate: string;
    SettlementSender: string; // UUID format as string
    TransferStatusGlobalMoneyStatus: string;
    CounterpartyThirdPerson: string; // UUID format as string, considering empty for null UUID
    SenderAddressDescription: string;
    MoneyTransferPaymentMethod: string | null;
    CalculatedWeight: number;
    OwnerDocumentType: string;
    ExpressWaybillPaymentStatus: string;
    SettlementRecipient: string; // UUID format as string
    TypeOfDocument: string;
    IsPossibilityDuplicate: boolean;
    RefEW: string; // UUID format as string
    Redelivery: number;
    RedeliverySum: string | null;
    RedeliveryNum: string;
    possibilityCreateReturn: boolean;
    possibilityChangeEW: boolean;
    possibilityChangeDeliveryIntervals: boolean;
    possibilityCreateRedirecting: boolean;
    possibilityCreateRefusa: boolean;
    possibilityChangeCash2Card: boolean;
    possibilityTermExtension: boolean;
    postomatV3CellReservationNumber: boolean;
    barcodeRedBox: boolean;
    recipientEDRPOU: string;
    SettlmentAddressData: SettlmentAddress;
}

export interface NovaPoshtaDocument {
    id: number;
    number: string;
    type: NovaPoshtaDocumentType;
    internetDocument: NovaPoshtaInternetDocument;
    trackingStatusCode: NovaPoshtaTrackingStatusCode;
}

export enum NovaPoshtaTrackingStatusCode {
    CREATED_BUT_NOT_SUBMITTED = 1,
    DELETED = 2,
    NUMBER_NOT_FOUND = 3,
    IN_TRANSIT_INTER_CITY = 4,
    IN_TRANSIT_LOCAL_STANDARD_EXPRESS = 41,
    HEADING_TO_CITY = 5,
    IN_CITY_EXPECTED_DELIVERY = 6,
    ARRIVED_AT_POST_OFFICE = 7,
    ARRIVED_AT_POSTOMAT = 8,
    RECEIVED = 9,
    RECEIVED_WITH_MONEY_TRANSFER_PENDING = 10,
    RECEIVED_MONEY_TRANSFER_ISSUED = 11,
    BEING_ASSEMBLED = 12,
    EN_ROUTE_TO_RECIPIENT = 101,
    RETURN_REQUESTED_BY_SENDER = 102,
    REFUSAL_BY_RECIPIENT = 103,
    ADDRESS_CHANGED = 104,
    STORAGE_HALTED = 105,
    RECEIVED_AND_RETURN_REQUEST_CREATED = 106,
    DELIVERY_ATTEMPT_FAILED_NO_RECIPIENT = 111,
    DELIVERY_DATE_POSTPONED_BY_RECIPIENT = 112,
}