import type {User} from 'src/types/user';
import api from "../api";
import {AxiosResponse} from "axios";
import {adminApi} from "../admin";
import {Admin} from "../../types/admin";
import {usersApi} from "../user";

type SignInRequest = {
    username: string;
    password: string;
}

type SignInResponse = Promise<{
    accessToken: string;
    refreshToken: string;
}>;

type SignUpRequest = {
    email: string;
    fullName: string;
    phone: string;
    password: string;
}

type SignUpResponse = Promise<boolean>;

type MeUserRequest = {
    userId: number
};

type MeUserResponse = Promise<User>;

type MeAdminResponse = Promise<Admin>;

class AuthApi {
    async signIn(request: SignInRequest): SignInResponse {
        const {username, password} = request;
        return new Promise((resolve, reject) => {
            api.post<SignInResponse>("/auth/login", {username, password})
                .then((response: AxiosResponse<SignInResponse>) => resolve(response.data))
                .catch((error) => reject(new Error(error.response.data.error)));
        });
    }

    async signUp(request: SignUpRequest): SignUpResponse {
        const {email, fullName, phone, password} = request;

        return new Promise((resolve, reject) => {
            try {
                api.post("/auth/registration", {email, password, fullName, phone})
                    .then((response) => {
                        if (response.status === 201) {
                            resolve(true);
                        } else {
                            reject(new Error('User register error'));
                        }
                    });
            } catch (err) {
                console.error('[Auth Api]: ', err);
                reject(new Error('Internal server error'));
            }
        });
    }

    meUser(request: MeUserRequest): MeUserResponse {
        return usersApi.getUser({userId: request.userId});
    }

    meAdmin(): MeAdminResponse {
        return adminApi.getAdminCurrent();
    }
}

export const authApi = new AuthApi();
