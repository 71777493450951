import * as React from "react";
import {FC, useCallback, useState} from "react";
import {Box, Button, Dialog, Divider, Stack, SvgIcon, TextField, Typography} from "@mui/material";
import {useAuth} from "../../../hooks/use-auth";
import Attachment01Icon from '@untitled-ui/icons-react/build/esm/Attachment01';
import {helpApi} from "../../../api/help";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import {tokens} from "../../../locales/tokens";

interface HelpButtonDialogProps {
    onClose: () => void;
    open: boolean;
}

export const HelpButtonDialog: FC<HelpButtonDialogProps> = (props) => {
    const {open, onClose} = props;
    const {user, admin} = useAuth();
    const [file, setFile] = useState<File>();
    const [issueText, setIssueText] = useState<string>();

    const {t} = useTranslation();

    const handleCapture = useCallback(
        ({target}: any): void => {
            setFile(target.files[0]);
        },
        []
    );

    const handleClose = useCallback(
        (): void => {
            setFile(undefined);
            setIssueText("")
            onClose();
        },
        []
    );

    const handleSend = useCallback(
        async (): Promise<void> => {
            try {
                const username = user ? user.username : admin ? admin.login : "";
                const request = {
                    username: username,
                    message: issueText,
                    file: file
                }
                await helpApi.sendMistakeMessage(request);
                setIssueText('');
                setFile(undefined);
                onClose();
                toast.success(t(tokens.help.successSendMessage));
            } catch (e) {
                toast.error("Error send message");
            }
        },
        [file, issueText]
    );

    const handelClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'backdropClick') {
            return;
        }
        if (onClose) {
            onClose();
        }
    };
    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handelClose}
            open={open ? open : false}
        >
            <Box
                sx={{p: 3}}
            >
                <Typography
                    align="center"
                    variant="h5"
                >
                    Повідомлення про помилку
                </Typography>
                <Stack
                    spacing={1}
                    sx={{p: 4}}
                >
                    <TextField
                        fullWidth
                        required
                        label="Username"
                        value={user ? user.username : admin?.login}
                        disabled
                        type="text"
                    />
                    <TextField
                        fullWidth
                        multiline
                        required
                        placeholder="Опишіть будь ласка проблему"
                        rows={5}
                        type="text"
                        value={issueText}
                        onChange={(event) => setIssueText(event.target.value)}
                    />
                </Stack>
                <Divider/>
                <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                    spacing={3}
                >
                    <Stack
                        alignItems="center"
                        direction="row"
                    >
                        <Button
                            component="label"
                            color="inherit"
                            size="large"
                            startIcon={
                                <SvgIcon>
                                    <Attachment01Icon/>
                                </SvgIcon>
                            }
                        >
                            <input
                                accept="image/*"
                                type="file"
                                hidden
                                onChange={(target) => handleCapture(target)}
                            />
                        </Button>
                        {
                            file && (
                                <Typography
                                    sx={{fontSize: 13}}
                                    variant="inherit"
                                >
                                    {file.name.length > 30 ? `${file.name.substring(0, 30)}...` : file.name}
                                </Typography>
                            )
                        }
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                    >
                        <Button
                            color="inherit"
                            onClick={() => handleClose()}
                        >
                            Скасувати
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={() => handleSend()}
                        >
                            Повідомити
                        </Button>
                    </Stack>
                </Stack>
            </Box>
        </Dialog>
    );
}