import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Type} from "../../../../../types/type";
import {Post} from "../../../../../types/post";

interface TypesState {
    isLoadingContactTypes: boolean,
    isLoadingDocumentTypes: boolean,
    isLoadingDocumentExchangeTypes: boolean,
    isLoadingPosts: boolean,
    contactTypes: Type[];
    documentTypes: Type[];
    documentExchangeTypes: Type[];
    userPosts: Post[];
}

const initialState: TypesState = {
    isLoadingContactTypes: false,
    isLoadingDocumentTypes: false,
    isLoadingDocumentExchangeTypes: false,
    isLoadingPosts: false,
    contactTypes: [],
    documentTypes: [],
    documentExchangeTypes: [],
    userPosts: []
};

type LoadingContactTypesAction = PayloadAction<boolean>;
type GetContactTypesAction = PayloadAction<Type[]>;
type DeleteContactTypesAction = PayloadAction<number>;
type UpdateContactTypesAction = PayloadAction<Type>;

type LoadingDocumentTypesAction = PayloadAction<boolean>;
type GetGetDocumentTypesAction = PayloadAction<Type[]>;
type DeleteDocumentTypesAction = PayloadAction<number>;

type LoadingDocumentExchangeTypesAction = PayloadAction<boolean>;
type GetDocumentExchangeTypesAction = PayloadAction<Type[]>;
type DeleteDocumentExchangeTypesAction = PayloadAction<number>;

type LoadingPostsAction = PayloadAction<boolean>;
type GetPostsAction = PayloadAction<Post[]>;
type DeletePostAction = PayloadAction<number>;
type UpdatePostAction = PayloadAction<Post>;

const reducers = {
    onLoadingContactTypes(state: TypesState, action: LoadingContactTypesAction): void {
        state.isLoadingContactTypes = action.payload;
    },
    onLoadingDocumentTypes(state: TypesState, action: LoadingDocumentTypesAction): void {
        state.isLoadingContactTypes = action.payload;
    },
    onLoadingDocumentExchangeTypes(state: TypesState, action: LoadingDocumentExchangeTypesAction): void {
        state.isLoadingContactTypes = action.payload;
    },
    onLoadingPosts(state: TypesState, action: LoadingPostsAction): void {
        state.isLoadingPosts = action.payload;
    },
    onGetContactTypes(state: TypesState, action: GetContactTypesAction): void {
        state.contactTypes = action.payload;
    },
    onDeleteContactTypes(state: TypesState, action: DeleteContactTypesAction): void {
        const types = [...state.contactTypes];
        const index = types.findIndex((type) => type.id === action.payload)
        if (index > -1) {
            types.splice(index, 1);
        }
        state.contactTypes = types;
    },
    onUpdateContactType(state: TypesState, action: UpdateContactTypesAction): void {
        let types = [...state.contactTypes];
        types = types.map((type) => {
            if (type.id === action.payload.id) {
                type.name = action.payload.name;
                type.enabled = action.payload.enabled;
            }
            return type;
        })
        state.contactTypes = types;
    },
    onGetDocumentTypes(state: TypesState, action: GetGetDocumentTypesAction): void {
        state.documentTypes = action.payload;
    },
    onDeleteDocumentTypes(state: TypesState, action: DeleteDocumentTypesAction): void {
        const types = [...state.documentTypes];
        const index = types.findIndex((type) => type.id === action.payload)
        if (index > -1) {
            types.splice(index, 1);
        }
        state.documentTypes = types;
    },
    onUpdateDocumentType(state: TypesState, action: UpdateContactTypesAction): void {
        let types = [...state.documentTypes];
        types = types.map((type) => {
            if (type.id === action.payload.id) {
                type.name = action.payload.name;
                type.enabled = action.payload.enabled;
                type.parentTypeEnabled = action.payload.parentTypeEnabled;
            }
            return type;
        })
        state.documentTypes = types;
    },
    onGetDocumentExchangeTypes(state: TypesState, action: GetDocumentExchangeTypesAction): void {
        state.documentExchangeTypes = action.payload;
    },
    onDeleteDocumentExchangeTypes(state: TypesState, action: DeleteDocumentExchangeTypesAction): void {
        const types = [...state.documentExchangeTypes];
        const index = types.findIndex((type) => type.id === action.payload)
        if (index > -1) {
            types.splice(index, 1);
        }
        state.documentExchangeTypes = types;
    },
    onUpdateDocumentExchangeType(state: TypesState, action: UpdateContactTypesAction): void {
        let types = [...state.documentExchangeTypes];
        types = types.map((type) => {
            if (type.id === action.payload.id) {
                type.name = action.payload.name;
                type.enabled = action.payload.enabled;
            }
            return type;
        })
        state.documentExchangeTypes = types;
    },
    onGetPostsTypes(state: TypesState, action: GetPostsAction): void {
        state.userPosts = action.payload;
    },
    onDeletePost(state: TypesState, action: DeletePostAction): void {
        const userPosts = [...state.userPosts];
        const index = userPosts.findIndex((post) => post.id === action.payload)
        if (index > -1) {
            userPosts.splice(index, 1);
        }
        state.userPosts = userPosts;
    },
    onUpdatePostType(state: TypesState, action: UpdatePostAction): void {
        let userPosts = [...state.userPosts];
        userPosts = userPosts.map((post) => {
            if (post.id === action.payload.id) {
                post.name = action.payload.name;
            }
            return post;
        })
        state.userPosts = userPosts;
    },
}

export const slice = createSlice({
    name: 'types',
    initialState,
    reducers
});

export const {reducer} = slice;