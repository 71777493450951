import axios, {AxiosResponse} from "axios";
import {apiServerConfig} from "../config";

const STORAGE_ACCESS_KEY = 'accessToken';
const STORAGE_REFRESH_KEY = 'refreshToken';

const api = axios.create({
    baseURL: apiServerConfig.apiURL,
    timeout: 80000,
});

api.interceptors.request.use(function (config) {
    const token = localStorage.getItem(STORAGE_ACCESS_KEY);
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

type RefreshTokenResponse = {
    accessToken: string;
    refreshToken: string;
};

async function refreshAccessToken() {
    try {
        const queryPath = "/auth/token/refresh?refreshToken=" + localStorage.getItem(STORAGE_REFRESH_KEY)
        api.post<RefreshTokenResponse>(queryPath)
            .then((response: AxiosResponse<RefreshTokenResponse>) => {
                localStorage.setItem(STORAGE_ACCESS_KEY, response.data.accessToken);
                localStorage.setItem(STORAGE_REFRESH_KEY, response.data.refreshToken);
            })
    } catch (err) {
        console.error(err);
        throw err;
    }
}

api.interceptors.response.use((response) => response, (error) => {
        const originalRequest = error.config;
        if (originalRequest.url !== "/auth/login" && error.response) {
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;
                const accessToken = localStorage.getItem(STORAGE_ACCESS_KEY);
                return refreshAccessToken().then(() => {
                    originalRequest.headers.Authorization = `Bearer ${accessToken}`;
                    return api(originalRequest);
                });
            }
        }
        return Promise.reject(error);
    }
);

export default api;